import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


const StyledTableCell = withStyles((theme) => ({
  root: {
    // borderRight : '1px dotted #000',
  },

  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontWeight: 'bold'

  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    //   backgroundColor: theme.palette.action.hover,

  },

}))(TableRow);


const useStyles = makeStyles({
  table: {
    minWidth: 700,
    maxHeight: 1000,
    overflowY: 'scroll',
    position: 'relative'
  },
});


function formatNumber(number) {
  let formatNum = (number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');  // 12,345.67
  return formatNum;
}

export default function BillTable(props) {

  const classes = useStyles();

  const Bills = props.bill;


  let sn = 1;

  let grandTotal = 0;
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} style={{ border: '1px solid #ddd', borderCollapse: 'collapse', padding: '8px' }} aria-label="customized table">
        <TableHead style={{ backgroundColor: "black", border: '1px solid black', color: "white", fontWeight: 'bold' }}>
          <TableRow style={{ position: 'sticky', top: '0', color: "white" }}>
            <StyledTableCell style={{ borderRight: '1px dotted #000' }}>SN</StyledTableCell>
            <StyledTableCell style={{ borderRight: '1px dotted #000' }}>Brand</StyledTableCell>
            <StyledTableCell style={{ borderRight: '1px dotted #000' }}>Product Model</StyledTableCell>
            <StyledTableCell style={{ width: '40%', borderRight: '1px dotted #000' }}>Description</StyledTableCell>
            <StyledTableCell style={{ borderRight: '1px dotted #000' }} align="right">Quantity</StyledTableCell>
            <StyledTableCell style={{ borderRight: '1px dotted #000' }} align="right">Unit Price</StyledTableCell>
            <StyledTableCell align="right">Total</StyledTableCell>
            {/* <StyledTableCell style={{borderRight : '1px dotted #000'}} align="right">Protein&nbsp;(g)</StyledTableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {Bills.length > 0 ? Bills.map((s) => {
            let serviceTotal = 0;
            return (
              <React.Fragment>
                <StyledTableRow style={{ backgroundColor: '#ddd' }} key={s.name}>
                  <StyledTableCell style={{ borderRight: '1px dotted #000', padding: '8px' }}></StyledTableCell>
                  <StyledTableCell style={{ borderRight: '1px dotted #000', padding: '8px' }}></StyledTableCell>
                  <StyledTableCell style={{ borderRight: '1px dotted #000', padding: '8px', fontWeight: 'bold' }}>{s.name.toUpperCase()}</StyledTableCell>
                  <StyledTableCell style={{ borderRight: '1px dotted #000', padding: '8px' }}></StyledTableCell>
                  <StyledTableCell style={{ borderRight: '1px dotted #000', padding: '8px' }} align="right"></StyledTableCell>
                  <StyledTableCell style={{ borderRight: '1px dotted #000', padding: '8px' }} align="right"></StyledTableCell>
                  <StyledTableCell align="right"></StyledTableCell>
                </StyledTableRow>
                {(s.rows).map((row) => {
                  let itemTotal = parseFloat(row.quantity) * parseFloat(row.unitprice);
                  serviceTotal += itemTotal;

                  grandTotal += itemTotal;
                  return (
                    <TableRow style={{ borderBottom: "1px solid #ddd" }} key={row.name}>
                      <StyledTableCell style={{ borderRight: '1px dotted #000', padding: '8px' }}>{sn++}</StyledTableCell>
                      <StyledTableCell style={{ borderRight: '1px dotted #000', padding: '8px' }}>{row.brand}</StyledTableCell>
                      <StyledTableCell style={{ borderRight: '1px dotted #000', padding: '8px' }}>{row.model}</StyledTableCell>
                      <StyledTableCell style={{ borderRight: '1px dotted #000', padding: '8px' }}>{row.description}</StyledTableCell>
                      <StyledTableCell style={{ borderRight: '1px dotted #000', padding: '8px' }} align="right">{row.quantity}</StyledTableCell>
                      <StyledTableCell style={{ borderRight: '1px dotted #000', padding: '8px' }} align="right">₦{formatNumber(parseFloat(row.unitprice))}</StyledTableCell>
                      <StyledTableCell align="right">₦{formatNumber(itemTotal)}</StyledTableCell>
                    </TableRow>
                  )
                })}
                <TableRow style={{ borderBottom: "1px solid #ddd" }}>
                  <StyledTableCell style={{ borderRight: '1px dotted #000', padding: '8px' }}></StyledTableCell>
                  <StyledTableCell style={{ borderRight: '1px dotted #000', padding: '8px' }}></StyledTableCell>
                  <StyledTableCell style={{ borderRight: '1px dotted #000', padding: '8px' }}></StyledTableCell>
                  <StyledTableCell style={{ borderRight: '1px dotted #000', padding: '8px' }}></StyledTableCell>
                  <StyledTableCell style={{ borderRight: '1px dotted #000', padding: '8px' }} align="right"></StyledTableCell>
                  <StyledTableCell style={{ borderRight: '1px dotted #000', padding: '8px' }} align="right"></StyledTableCell>
                  <StyledTableCell align="right" style={{ fontWeight: 'bold' }}>₦{formatNumber(serviceTotal)}</StyledTableCell>
                </TableRow>
              </React.Fragment>
            )
          })
            :
            ""
          }
          <TableRow style={{ borderBottom: "1px solid #ddd" }}>
            <StyledTableCell style={{ borderRight: '1px dotted #000', padding: '8px' }}></StyledTableCell>
            <StyledTableCell style={{ borderRight: '1px dotted #000', padding: '8px' }}></StyledTableCell>
            <StyledTableCell style={{ borderRight: '1px dotted #000', padding: '8px' }}></StyledTableCell>
            <StyledTableCell style={{ borderRight: '1px dotted #000', padding: '8px' }}></StyledTableCell>
            <StyledTableCell style={{ borderRight: '1px dotted #000', padding: '8px' }} ></StyledTableCell>
            <StyledTableCell style={{ borderRight: '1px dotted #000', padding: '8px' }} ></StyledTableCell>
            <StyledTableCell  ></StyledTableCell>
          </TableRow>
          <StyledTableRow style={{ backgroundColor: 'skyblue' }}>
            <StyledTableCell ></StyledTableCell>
            <StyledTableCell ></StyledTableCell>
            <StyledTableCell ></StyledTableCell>
            <StyledTableCell style={{ fontWeight: 'bold', fontSize: '18px' }}>GRAND TOTAL</StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right" style={{ fontWeight: 'bold', fontSize: '18px' }}>₦{formatNumber(grandTotal)}</StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
