import { Container, Typography } from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import { send } from 'emailjs-com';

import BillsTable from "../components/BillsTable";
import { Link, Redirect, useLocation } from "react-router-dom";
import billContext from '../contexts/billContext';

import { services, getaccessories, smartIntercom } from '../utils/data';

import Button from '@material-ui/core/Button';

import { PDFDownloadLink } from "@react-pdf/renderer";
import { PdfDocument } from "../components/PdfDocument";
import customerContext from "../contexts/customerContext";
import apiUrlContext from "../contexts/apiUrlContext";
import renderPop from '../components/popup';


function exportToExcel() {
    var htmls = "";
    var uri = 'data:application/vnd.ms-excel;base64,';
    var template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    var base64 = function (s) {
        return window.btoa(unescape(encodeURIComponent(s)))
    };

    var format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
        })
    };

    htmls = document.getElementById("table").innerHTML;

    var ctx = {
        worksheet: 'Worksheet',
        table: htmls
    }


    var link = document.createElement("a");
    link.download = "export.xls";
    link.href = uri + base64(format(template, ctx));
    link.click();
}

const BillOfMaterials = () => {
    const [Bills, setBills] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [SO, setSO] = useState([]);
    const [other, setOther] = useState([]);
    const [loading, setLoad] = useState(false);

    const [redirect, setRedirect] = useState(null);

    const { apiUrl, putUrl } = useContext(apiUrlContext);

    // const {bill, putBill} = useContext(billContext);

    const location = useLocation();


    // const {customer, putCustomer} = useContext(customerContext);

    const sendMail = () => {
        const html = document.getElementById("table").innerHTML;
        const values = { ...customer, html: html }

        console.log(values);

        send(
            "service_g5acpxj",
            "user_product",
            values,
            'user_8rIPDFOqhTIFAtD6NyeCp'
        ).then((response) => {
            console.log('SUCCESS!', response.status, response.text);
        }).catch((err) => {
            console.log('FAILED...', err);
        });

    }

    useEffect(() => {


        document.getElementById("copyBtn").addEventListener("click", function () {

            let r = document.createRange();
            r.selectNode(document.getElementById("table"));
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(r);
            let copy = document.execCommand('copy');
            window.getSelection().removeAllRanges();

            alert("Copied the text: " + copy);
            exportToExcel();
        });

    }, []);

    useEffect(() => {
        if (location.state === undefined) {
            setRedirect('/');
        }
        else {
            const { bill, customer, user, quoteno, SO } = location.state;

            // console.log(bill)

            // const newBills = makeBills(bill);
            // setBills(newBills);  
            const set = { user: user, quoteno: quoteno };
            console.log('set', set);
            setOther(set);
            setBills(bill);
            setCustomer(customer);
            setSO(SO);
        }

    }, []);

    useEffect(() => {
        if (Object.keys(Bills).length > 0) {

            sendMail();
        }
    }, [Bills, customer]);


    async function createSO(customer, SO) {
        setLoad(true);

        customer = JSON.stringify(customer)
        await fetch(
            `${apiUrl}api/createSO`,
            {
                method: "POST",
                headers: new Headers({
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify({ data: { customer: customer, kits: SO } })
            }
        )
            .then(res => res.json())
            .then(response => {
                response.status ? response.status == 1 ? renderPop('success', 'SO created Successfully.') : renderPop('danger', 'An error occurred.') : renderPop('danger', 'An error occurred.')
                setLoad(false)
            })
            .catch(error => console.log(error));

    }

    if (redirect !== null) {
        return <Redirect to={redirect} />
    }



    return (

        <Container>
            <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'row', margin: 20 }}>
                <Link to="/" style={{ textDecoration: 'none', flex: 1 }}>
                    <Button variant="outlined" color="primary">
                        Back
                    </Button>
                </Link>

                <Link to={{
                    pathname: "/viewpdf",
                    state: {
                        data: Bills,
                        customer: customer,
                        user: other.user,
                        quoteno: other.quoteno
                    },
                }} style={{ textDecoration: 'none', flex: 1, alignItems: 'center' }}>
                    <Button variant="outlined" color="primary">
                        View PDF
                    </Button>
                </Link>

                <span style={{ flex: 1, alignItems: 'center' }}>
                    <Button disabled={loading} variant="outlined" color="primary" onClick={() => createSO(customer, SO)}>
                        {loading ?
                            "Loading.."
                            :
                            "Create An SO"}

                    </Button>
                </span>



                <Button variant="contained" id="copyBtn" color="primary" style={{ alignItems: 'end' }} >
                    Copy/Download Excel
                </Button>

                {/* <PDFDownloadLink document={<PdfDocument data={Bills} customer= {customer} />} fileName="bill_of_materials.pdf" style={{ textDecoration: "none",  alignItems: 'end' }}>
                    <Button variant="contained" color="primary" >
                        Download PDF
                    </Button> 
                </PDFDownloadLink> */}
            </div>
            <Typography variant="h4" style={{ textAlign: 'center', margin: '20px' }}> Bill of Materials</Typography>
            <div id="table">
                <BillsTable bill={Bills} />

            </div>
            <div style={{ height: '100px' }}></div>

        </Container>
    );
}

export default BillOfMaterials;