
export const services = [
    {
        'name': 'Starter Kit',
        'icon': 'LockIcon',
        'detail': [
            {
                'title': "Main Security Panel",
                'image': "main-security-panel.jpg",
                'description': "A Main Security Panel is the primary controller of a home's security system. Door and window sensors. Motion sensors, both interior and exterior. Wired or wireless security cameras. A high-decibel siren or alarm.",
                'brand': "QOLSYS",
                'model': "QS9212-0G09-826",
                'pdescription': "IQ Panel 2 Plus: The most powerful security and smarthome platform gets Dual SRF, featuring  PowerG",
                'price': "401.49"

            },
            {
                'title': "Alarm.com Subscription",
                'image': "subscription.jpg",
                'description': "An annual subscription for Alarm.com.",
                'brand': "Alarm.com",
                'model': "ADC-1YR-SUB-IG",
                'pdescription': "Alarm.com Annual Subscription - Interactive Gold",
                'price': "130.26"
            },
            {
                'title': "Security Light Switch",
                'image': "slightswitch.jpg",
                'description': "Smart switch for integrating security lights to smart lighting system.",
                'brand': "Aeotec",
                'model': "ZW116",
                'pdescription': "[ZW116] Aeotec - Nano Switch",
                'price': "50.00"
            },
            {
                'title': "Doorbell Camera",
                'brand': "Alarm.com",
                'model': "ADC-VDB105",
                'image': "doorbell-camera.jpg",
                'pdescription': "Alarm.com WiFi Doorbell HD Camera (in Satin Nickel)",
                'price': "230.05",
                'quantity': '1'

            },
            {
                'title': "Door Chime",
                'brand': "CED",
                'model': "HC24-CHIME",
                'image': "indoor-chime.jpg",
                'pdescription': "Indoor Door Chime for Doorbell",
                'price': "49.27",
                'quantity': '2'

            },
            {
                'title': "Smart Wireless Lock",
                'image': "smart-wireless-lock.jpg",
                'description': "With smart locks, you never have to carry a physical key. They have keypads for PINs and/or electronic keys, where the 'key' lives in an app on your smartphone and communicates with your lock wirelessly.",
                'brand': "Danalock",
                'model': "DANALOCK-V3-BTZE",
                'pdescription': "Danalock V3 Silver Electric Lock",
                'price': "198.70"
            },
            {
                'title': "Smart Wireless Keypad",
                'image': "smart-wireless-keypad.jpg",
                'description': "The Smart Wireless Keypad gives you easy hotkey access to Device remote control functions such as power on/off, channel changing and volume adjustment.",
                'brand': "Danalock",
                'model': "DANAPAD-V3",
                'pdescription': "Danapad Bluetooth",
                'price': "97.03"
            },

        ]

    },
    {
        'name': 'Smart Security',
        'icon': 'LockIcon',
        'detail': [
            {
                'title': "Motion Detector",
                'image': "motion-detector.jpg",
                'description': "Monitored Smoke Detector not only senses smoke but also rapid rises in heat from fire to keep you protected.",
                'brand': "DSC",
                'model': "PG8914",
                'pdescription': "Wireless powered motion detector with pet immunity 868mhz",
                'price': "51.94"

            },
            {
                'title': "Smoke Detector",
                'image': "smoke-detector.jpg",
                'description': "A security door contact is usually a device consisting of a sensor. When the door is opened, a signal is sent to the panel.",
                'brand': "DSC",
                'model': "PG8916",
                'pdescription': "Wireless powered smoke detector 868mhz",
                'price': "78.70"
            },
            {
                'title': "Door Contact",
                'image': "door-contact.jpg",
                'description': "A motion sensor alarm is a device that uses optical sensor technologies to detect motion around your home or workspace.",
                'brand': "DSC",
                'model': "PG8945",
                'pdescription': "Wireless powered door/window contact with auxiliary input 868mhz",
                'price': "33.57"
            }
        ]

    },
    // {
    //   'name' : 'Smart Access',
    //   'icon' : 'HomeIcon',
    //   'detail' : [
    //       {
    //           'title' : "Smart Wireless Lock",
    //           'image' : "smart-wireless-lock.jpg",
    //           'description' : "With smart locks, you never have to carry a physical key. They have keypads for PINs and/or electronic keys, where the 'key' lives in an app on your smartphone and communicates with your lock wirelessly.",
    //           'brand' : "Danalock",
    //           'model' : "DANALOCK-V3-BTZE",
    //           'pdescription' : "Danalock V3 Silver Electric Lock",
    //           'price' : "198.70"
    //       },
    //       {
    //           'title' : "Smart Wireless Keypad", 
    //           'image' : "smart-wireless-keypad.jpg", 
    //           'description' : "The Smart Wireless Keypad gives you easy hotkey access to Device remote control functions such as power on/off, channel changing and volume adjustment.",
    //           'brand' : "Danalock",
    //           'model' : "DANAPAD-V3",
    //           'pdescription' : "Danapad Bluetooth",
    //           'price' : "97.03"
    //       },

    //   ]
    // },
    {
        'name': 'Smart Surveillance',
        'icon': 'VideocamIcon',
        'detail': [
            {
                'title': "Stream Video Recorder",
                'image': "stream-video-recorder.jpg",
                'description': "The Stream Video Recorder is a device that allows a user to store digital recordings locally so that they can be accessed later through Alarm.com",
                'brand': "Alarm.com",
                'model': "ADC-SVR122",
                'pdescription': "Alarm.Com SVR122 Stream Video Recorder 1TB SVR",
                'price': "281.46"
            },
            {
                'title': "Wired Dome Camera",
                'image': "wired-dome-camera.jpg",
                'description': "This is a high performance fixed dome camera optimised for indoor and outdoor video monitoring.",
                'brand': "Alarm.com",
                'model': "ADC-VC826",
                'pdescription': "Alarm.com 1080P HD Indoor/Outdoor Dome Security Camera",
                'price': "141.70"
            },
            {
                'title': "Wired Bullet Camera",
                'image': "wired-bullet-camera.jpg",
                'description': "This is a high-performance indoor/outdoor bullet camera with powerful night vision capabilities.",
                'brand': "Alarm.com",
                'model': "ADC-VC736",
                'pdescription': "Alarm.com Indoor/Outdoor PoE Bullet Camera",
                'price': "141.70"
            },
            {
                'title': "Wireless Indoor Camera",
                'image': "wireless-id-camera.jpg",
                'description': "This is latest wireless IP night vision camera.",
                'brand': "Alarm.com",
                'model': "ADC-V522IR",
                'pdescription': "Alarm.com 1080P Indoor WiFi Video Camera",
                'price': "115.00"
            },
            {
                'title': "Guardian Camera", //Discrete Table Camera wt Audio
                'image': "discrete-table-camera.jpg",
                'description': "This is a not so obvious smaller camera that can be used for discrete surveillance",
                'brand': "Alarm.com",
                'model': "ADC-V622-40-INT",
                'pdescription': "The Alarm.com Wellness Camera. Offers a 1080P recording quality, two-way voice and a full 180 degree viewing angle",
                'price': "293.33"
            },
            {
                'title': "10P Network Switch",
                'image': "iop-network-switch.jpg",
                'description': "Switches facilitate the sharing of resources by connecting together all the devices in a small home network. Connected devices can share information and talk to each other, regardless of where they are in a building.",
                'brand': "Luxul",
                'model': "XMS-1010P",
                'pdescription': "10-Port/8 POE+ Gigabit Managed Switch",
                'price': "301.77"
            }
        ]

    },
    {
        'name': 'Smart Lighting',
        'icon': 'EmojiObjectsOutlinedIcon',
        'detail': [
            {
                'title': "Wireless Inline Dimmer",
                'image': "wireless-inline-dimmer.jpg",
                'description': "An LED Dimmer Switch allows you to adjust the brightness of LED lights with a classic rotary knob without any troublesome flickering.",
                'brand': "Lutron",
                'model': "RRK-R25NE-240",
                'pdescription': "250W LED/Hal/Inc/Elv in-line RF dimmer with neutral wire",
                'price': "130.12"
            },
            {
                'title': "Wireless Inline Switch",
                'image': "wireless-inline-switch.jpg",
                'description': "A Wireless Inline Switch allows you to switch lights on and off with ease.",
                'brand': "Lutron",
                'model': "RRK-R6ANS-240",
                'pdescription': "6A general lighting (2A motor) in-line RF switch with neutral wire",
                'price': "130.12"
            },
            {
                'title': "AC/Water Heater Switch",
                'image': "ac-switch.jpg",
                'description': "You can switch your water heater and air-conditioner on or off with ease, from anywhere.",
                'brand': "Lutron",
                'model': "LMK-16R-DV-B",
                'pdescription': "RF Relay Module with Softswitch—16A (adaptable box not included)",
                'price': "165.64"
            },
            {
                'title': "Motion Sensor",
                'image': "motion-detector.jpg",
                'description': "A motion sensor alarm is a device that uses optical sensor technologies to detect motion around your home or workspace.",
                'brand': "CED",
                'model': "CED-SENSOR",
                'pdescription': "Standalone Sensor	18",
                'price': "28.01"
            },
            {
                'title': "Single Pico Keypad",
                'image': "single-pico-keypad.jpg",
                'description': "The Pico wireless control for audio/lights is a simple keypad for direct control of audio/lights from anywhere in the home.",
                'brand': "Lutron",
                'model': "LPFP-S1-TAW",
                'pdescription': "Wallplate for single Pico control",
                'price': "16.95"
            },
            {
                'title': "Dual Scene Keypad",
                'image': "dual-scene-keypad.jpg",
                'description': "The Dual Scene Keypad lets you control the lights in a room based on your desired scene.",
                'brand': "Lutron",
                'model': "LPFP-S2-TAW",
                'pdescription': "Wallplate for two Pico controls",
                'price': "16.95"
            },
            {
                'title': "Wireless Main Repeater",
                'image': "wifi-repeater.jpg",
                'description': "Main Repeaters extend the range of Radio Frequency (RF) signals that are sent between devices. They create a wireless network in the home and ensure reliable performance.",
                'brand': "Lutron",
                'model': "RRK-SEL-REP2-BL",
                'pdescription': "RA2 Select Main Repeater. Allows for setup, control, and monitoring of multiple devices",
                'price': "241.77"
            },
            {
                'title': "Auxiliary Repeater",
                'image': "aux-repeater.jpg",
                'description': "Auxiliary repeater extends system RF range in larger spaces and between buildings. Can be used wirelessly within the home or be wired to another repeater to span longer distances.",
                'brand': "Lutron",
                'model': "LK-REPPRO-BL",
                'pdescription': "Wireless Auxiliary Repeater. Use up to four to extend the RF range",
                'price': "196.09"
            },
        ]

    },
    {
        'name': 'Smart Audio',
        'icon': 'SpeakerOutlinedIcon',
        'detail': [

            //   {
            //     'title' : "6.5\" In-ceiling Speaker (Pair)",
            //     'image' : "ceiling-speaker.jpg",
            //     'description' : "In-ceiling speakers are speakers installed in the ceiling, mounted down-firing from the ceiling above.",
            //     'brand' : "Origin Acoustics",
            //     'model' : "P61",
            //     'pdescription' : "Producer In-ceiling speaker with a thin bezel, 6.5\" Polypropylene woofer and pivoting silk dome tweete",
            //     'price' : "319.75"
            // },
            //   {
            //       'title' : "8\" In-ceiling Speaker (Pair)", 
            //       'image' : "ceiling-speaker.jpg", 
            //       'description' : "In-ceiling speakers are speakers installed in the ceiling, mounted down-firing from the ceiling above.",
            //       'brand' : "Origin Acoustics",
            //       'model' : "P80",
            //       'pdescription' : "Producer In-ceiling speaker with a thin bezel, 8\" Polypropylene woofer and pivoting silk dome tweete",
            //       'price' : "387.10"
            //   },
            //   {
            //       'title' : "Outdoor On-wall Speaker (Pair)", 
            //       'image' : "outdoor-speakers.jpg", 
            //       'description' : "On-wall speakers are speakers installed on the walls, mounted front-firing with the woofers pointed horizontally across the room",
            //       'brand' : "Sonos",
            //       'model' : "OUTDRWW1",
            //       'pdescription' : "Sonos Outdoor Speakers(Pair): The architectural speakers by Sonance for outdoor listening.",
            //       'price' : "876.44"
            //   },
            //   {
            //       'title' : "Single Zone Amplifier",
            //       'image' : "single-zone-amplifier.jpg",
            //       'description' : "Single Zone Amplifiers give you the possibility of playing and controlling music for each room withon your home independently.",
            //       'brand' : "Sonos",
            //       'model' : "AMPG1UK1BLK",
            //       'pdescription' : "Sonos Amp: The versatile amplifier for powering all your entertainment.",
            //       'price' : "753.52"
            //   },
            {
                'title': "Sonos One SL",
                'image': "sonos-one-sl.jpg",
                'description': "Get rich, room-filling sound with Sonos One, and control it with your voice, the Sonos app, Apple AirPlay 2, and more.",
                'brand': "Sonos",
                'model': "ONEG2UK1BLK",
                'pdescription': "Sonos ONE SL UK. The powerful microphone-free speaker for music and more.",
                'price': "267.93"
            },
            {
                'title': "Sonos ARC (Soundbar)",
                'image': "sonos-soundbar.jpg",
                'description': "A sound bar is an all-in-one speaker system that delivers high-quality TV sound without requiring the space, complexity, and expense of a home theater receiver and surround sound speaker setup.",
                'brand': "Sonos",
                'model': "ARCG1US1BLK",
                'pdescription': "Sonos ARC: The premium smart soundbar for TV, movies, music, gaming, and more",
                'price': "973.89"
            },
            {
                'title': "Sonos Beam (Soundbar)",
                'image': "sonos-beam.jpg",
                'description': "A sound bar is an all-in-one speaker system that delivers high-quality TV sound without requiring the space, complexity, and expense of a home theater receiver and surround sound speaker setup.",
                'brand': "Sonos",
                'model': "BEAM1UK1BLK",
                'pdescription': "Sonos Beam: The smart, compact soundbar for TV, music, and more. White",
                'price': "569.06"
            },
            {
                'title': "Sonos SUB",
                'image': "sonos-sub.jpg",
                'description': "Connect Sub to your Sonos system for booming, distortion-free low-end. Create an immersive experience for your music, movies, games, and more.",
                'brand': "Sonos",
                'model': "SUBG1UK1BLK",
                'pdescription': "Sonos Sub: The wireless subwoofer for deep bass. White",
                'price': "1186.16"
            },
            {
                'title': "Sonos Five",
                'image': "sonos-five.jpg",
                'description': "Alone or in a stereo pair, Sonos Five fills rooms with pulse-pounding sound. It's the most powerful wireless speaker ever made.",
                'brand': "Sonos",
                'model': "PL5G2US1BLK",
                'pdescription': "Sonos FIVE Ultimate wireless Smart speaker for streaming music.",
                'price': "635.66"
            },
            {
                'title': "Sonos One",
                'image': "sonos-one.jpg",
                'description': "Get rich, room-filling sound with Sonos One, and control it with your voice, the Sonos app, Apple AirPlay 2, and more.",
                'brand': "Sonos",
                'model': "ONEG2UK1BLK",
                'pdescription': "Sonos One SL The powerful microphone-free speaker for music and more. (Black)",
                'price': "293.18"
            },
            {
                'title': "Sonos Move",
                'image': "sonos-move.jpg",
                'description': "Get brilliant sound anywhere with the weatherproof and drop-resistant Move. Control with your voice, the Sonos app, and Apple AirPlay 2 at home, and stream via Bluetooth® when WiFi isn't available.",
                'brand': "Sonos",
                'model': "MOVE1UK1BLK",
                'pdescription': "Sonos Move: A Portable Wifi + Bluetooth Speaker. The durable, battery-powered smart speaker for outdoor and indoor listening",
                'price': "571.78"
            }
        ]

    },
];

export const smartIntercom =
{
    'name': 'Smart Intercom',
    'icon': 'None',
    'detail': [
        {
            'title': "Doorbell Camera",
            'brand': "Alarm.com",
            'model': "ADC-VDB105",
            'image': "doorbell-camera.jpg",
            'pdescription': "Alarm.com WiFi Doorbell HD Camera (in Satin Nickel)",
            'price': "230.05",
            'quantity': '1'

        },
        {
            'title': "Door Chime",
            'brand': "CED",
            'model': "HC24-CHIME",
            'image': "indoor-chime.jpg",
            'pdescription': "Indoor Door Chime for Doorbell",
            'price': "49.27",
            'quantity': '2'

        },

    ]

};

export const getaccessories = {
    '0': [
        {
            'title': "Network Sibnal Repeater", //Main Security Panel Accessory
            'brand': "Aeotec",
            'model': "ZW189-C07",
            'image': "network-signal-repeater.jpg",
            'pdescription': "Z-Wave network signal repeater and amplifier offering SmartStart, S2 security, and built upon Aeotec's Gen7 technology stack.",
            'price': "61.10"
        },
        {
            'title': "Alarm.com Doorbell Camera Subscription", //Doorbell Camera Accessory
            'brand': "Alarm.com",
            'model': "ADC-1YR-SUB-VDB",
            'image': "subscription.jpg",
            'pdescription': "Alarm.com Annual Subscription - Door Bell Camera",
            'price': "32.06",
        },
        {
            'title': "Installation and Programming",
            'brand': "HC24",
            'model': "HC24-STARTER-INST",
            'image': "subscription.jpg",
            'pdescription': "Installation and Programming for Starter Kit",
            'price': "tbd",
        },
        {
            'title': "Isreali Cylinder", //Smart Wireless Lock Accessory
            'brand': "Danalock",
            'model': "DAN-ECYL",
            'image': "isreali-cylinder.jpg",
            'pdescription': "Danalock Israeli Cylinder",
            'price': "40.43"
        }
    ],
    '2': [
        {
            'title': "0.5M Patch Cord", //Equal to Number of Cameras + 1
            'brand': "CED",
            'model': "0.5-MCAT6A",
            'image': "cord.jpg",
            'pdescription': "CED - 0.5M CAT6A Patch Cord(Black)",
            'price': "1.73"
        },
        {
            'title': "HDMI Cable", //Stream Video Recorder Accessory
            'brand': "CED",
            'model': "CED-HDMI-CABLE",
            'image': "cable.jpg",
            'pdescription': "Locking Hdmi Cables V1.4 With Fast Ethernet Supporting 3d, 4k",
            'price': "17.28"
        },
        {
            'title': "Alarm.com Stream Video Recorder Sub",//Stream Video Recorder Accessory
            'brand': "Alarm.com",
            'model': "ADC-1YR-SUB-SVR",
            'image': "subscription.jpg",
            'pdescription': "Alarm.com Annual Subscription - Stream Video Recorder",
            'price': "39.08"
        },
        {
            'title': "Alarm.com Pro Video Sub", //Stream Video Recorder Accessory
            'brand': "Alarm.com",
            'model': "ADC-1YR-SUB-PVA",
            'image': "subscription.jpg",
            'pdescription': "Alarm.com Annual Subscription - Pro Video With Analytics for 4 Cameras",
            'price': "77.15"
        },
        {
            'title': "Alarm.com Wellness Sub",//Discrete Table Camera wt Audio Accessory
            'brand': "Alarm.com",
            'model': "ADC-1YR-SUB-WELL",
            'image': "subscription.jpg",
            'pdescription': "Alarm.com Annual Subscription - Wellness",
            'price': "50.10"
        },
        {
            'title': "Alerm.com Additional Cameras Sub", //Added for every additional 4 cameras, when total cameras exceed 4
            'brand': "Alarm.com",
            'model': "ADC-1YR-SUB-4CAM",
            'image': "subscription.jpg",
            'pdescription': "Alarm.com Annual Subscription - 4 Additional Cameras",
            'price': "65.13"
        }
    ],
    '3': [
        {
            'title': "4Button Keypad", //Look at Formular (total single keypad minus total dual keypad)
            'brand': "Lutron",
            'model': "PK2-4B-TAW-P03",
            'image': "4button-keypad.jpg",
            'pdescription': "4-button pre-engraved keypad with Bright, Entertain, Movie, and Off scenes",
            'price': "73.43"
        },
        {
            'title': "Single Pico Keypad", //Look at Formular (total of single and dual keypad)
            'brand': "Lutron",
            'model': "PK2-4B-TAW-L21",
            'image': "single-pico-keypad.jpg",
            'pdescription': "4-button, 2-Group on/off light controls with light icons.",
            'price': "73.43"
        },
        {
            'title': "Pre-engraved Pico Keypad",//Equal to 1 if Entrance Porch location is active for smart lighting
            'brand': "Lutron",
            'model': "PK2-2B-TAW-P01",
            'image': "single-pico-keypad.jpg",
            'pdescription': "2-button pre-engraved keypad with Home and Away scenes",
            'price': "73.43"
        }

    ],
    '4': [
        {
            'title': "Sonos AMP Wallbracket", //Single Zone Amplifier Accessory
            'brand': "Flexson",
            'model': "FLXSAWM1021",
            'image': "wallbracket.jpg",
            'pdescription': "Sonos AMP wallbracket",
            'price': "103.78"
        },
        {
            'title': "ARC Wall Mount", //Sonos ARC (Soundbar) Accessory
            'brand': "Flexson",
            'model': "ARCWMWW1BLK",
            'image': "wallmount.jpg",
            'pdescription': "ARC Wall Mount",
            'price': "105.50"
        },
        {
            'title': "BEAM WAll Mount", //Sonos Beam (Soundbar) Accessory
            'brand': "Flexson",
            'model': "FLXBFWM1021",
            'image': "BEAM-Wall-Mount.jpg",
            'pdescription': "BEAM Wall Mount",
            'price': "63.40"
        },

    ],
    'all': {
        'cable': [
            {
                'title': "Speaker Cable",
                'brand': "CED",
                'model': "16AWG-4C-SPK",
                'image': "speaker-cable.jpg",
                'pdescription': "16AWG-4C Speaker Cable",
                'price': "1.73"
            },
            {
                'title': "LAN Cable",
                'brand': "CED",
                'model': "CAT6A-UTP",
                'image': "UTP-Cable.jpg",
                'pdescription': "CAT6A Unshielded Twisted Pair LAN Cable (4 Twisted Pair)",
                'price': "0.65"
            },
        ],
        'shades': [
            {
                'brand': "Somfy",
                'model': "SOMFY-DRAPERY-MOTOR",
                'pdescription': "Somfy-powered motorized drapery track motor.",
                'price': "300.00"
            },
            {
                'brand': "Somfy",
                'model': "SOMFY-DRAPERY-1M",
                'pdescription': "Somfy-powered motorized drapery track. 1 meter.",
                'price': "60.00"
            },
            {
                'brand': "Somfy",
                'model': "1810493",
                'pdescription': "Somfy 1810493 Dry Contact Interface",
                'price': "160.46"
            },
            {
                'brand': "Somfy",
                'model': "SOMFY-1CH-REMOTE",
                'pdescription': "Somfy RTS Remote Control (1 Channel)",
                'price': "80.00"
            },
            {
                'brand': "Somfy",
                'model': "SOMFY-16CH-REMOTE",
                'pdescription': "Somfy RTS Remote Control (16 Channel)",
                'price': "250.00"
            },
            {
                'brand': "Somfy",
                'model': "SOMFY-4CH-REMOTE",
                'pdescription': "Somfy RTS Remote Control (4 Channel)",
                'price': "100.00"
            },
            {
                'brand': "Somfy",
                'model': "SOMFY-ROL-MOTOR",
                'pdescription': "Somfy-powered motorized roller shades.",
                'price': "300.00"
            },
            {
                'brand': "Somfy",
                'model': "SOMFY-FABRIC-1SQM",
                'pdescription': "Shades Fabric (1sqm)",
                'price': "49.66"
            },
            {
                'brand': "Qubino",
                'model': "ZMNHND3",
                'pdescription': "Qubino Z-Wave Plus Universal Relay with Dry Contact Switch",
                'price': "125.00"
            }
        ]
    },

};

export const starterKits = [
    {
        'index': "0",
        'title': "Starter Kit 1",
        'image': "starter-1.jpg",
        'description': `<span>Starter Kit for Smart Security System. This systems involves Intrusion & Fire alarm sensors.<br/>
                            * Touchscreen Control Panel (1)<br/>
                            * Door Contact (2)<br/>
                            * Smoke & Heat Detector (1)<br/>
                            * Security Light Switch (1)<br/>
                            * HC24 Alarm.com Interactive Gold Subscription<br/>
                            * Installation & Programming
                        </span>`,
        'pdescription': `Starter Kit for Smart Security System. This systems involves Intrusion & Fire alarm sensors.
                            * Touchscreen Control Panel (1)
                            * Door Contact (2)
                            * Smoke & Heat Detector (1)
                            * Security Light Switch (1)
                            * HC24 Alarm.com Interactive Gold Subscription
                            * Installation & Programming`,
        'price': "871.68",
        "items": {
            "all": {
                "00": "1", "12": "2", "11": "1", "01": "1", "02": "1"
            },
            "accessories": {
                "02": 1
            }
        }
    },
    {
        'index': "1",
        'title': "Starter Kit 2",
        'image': "starter-2.jpg",
        'description': `<span>Starter Kit for Smart Security System . This systems involves Intrusion & Fire alarm sensors.<br/>
                            *Touchscreen Control Panel (1)<br/>
                            * Door Contact (2)<br/>
                            * Smoke & Heat Detector (1)<br/>
                            * Doorbell Camera (1)<br/>
                            * Indoor Door Chime (1)<br/>
                            * Security Light Switch (1)<br/>
                            * HC24 Alarm.com Interactive Gold Subscription<br/>
                            * Installation & Programming
                        </span>`,
        'pdescription': `Starter Kit for Smart Security System . This systems involves Intrusion & Fire alarm sensors.
                        *Touchscreen Control Panel (1)
                        * Door Contact (2)
                        * Smoke & Heat Detector (1)
                        * Doorbell Camera (1)
                        * Indoor Door Chime (1)
                        * Security Light Switch (1)
                        * HC24 Alarm.com Interactive Gold Subscription
                        * Installation & Programming`,
        'price': "1181.68",
        "items": {
            "all": {
                "00": "1", "12": "2", "11": "1", "01": "1", "02": "1", "03": "1", "04": "1"
            },
            "accessories": {
                "02": 1
            }
        }
    },
    {
        'index': "2",
        'title': "Starter Kit 3",
        'image': "starter-3.jpg",
        'description': `<span>Starter Kit for Smart Security System . This systems involves Intrusion & Fire alarm sensors. Includes installation (6.5 Hours)<br/>
                            *Touchscreen Control Panel (1)<br/>
                            * Door Contact (2)<br/>
                            * Motion Sensor (1)<br/>
                            * Smoke & Heat Detector (1)<br/>
                            * Doorbell Camera (1)<br/>
                            * Indoor Chime (1)<br/>
                            * Danalock Smart Lock (1)<br/>
                            * Danalock Keypad (1)<br/>
                            * Dana Isreali Cyclinder (1)<br/>
                            * Security Light Switch (1)<br/>
                            * HC24 Alarm.com Interactive Gold Subscription<br/>
                            * Installation and programming.
                        </span>`,
        'pdescription': `Starter Kit for Smart Security System . This systems involves Intrusion & Fire alarm sensors. Includes installation (6.5 Hours)
                            *Touchscreen Control Panel (1)
                            * Door Contact (2)
                            * Motion Sensor (1)
                            * Smoke & Heat Detector (1)
                            * Doorbell Camera (1)
                            * Indoor Chime (1)
                            * Danalock Smart Lock (1)
                            * Danalock Keypad (1)
                            * Dana Isreali Cyclinder (1)
                            * Security Light Switch (1)
                            * HC24 Alarm.com Interactive Gold Subscription
                            * Installation and programming.`,
        'price': "1886.92",
        "items": {
            "all": {
                "00": "1", "12": "2", "10": "1", "11": "1", "05": "1", "06": "1", "01": "1", "02": "1", "03": "1", "04": "1"
            },
            "accessories": {
                "03": "1", "02": 1
            }
        }
    },
    {
        'index': "3",
        'title': "Starter Kit 4",
        'image': "starter-4.jpg",
        'description': `<span>Starter Kit for Smart Security System . This systems involves Intrusion & Fire alarm sensors. Includes installation (7.5 Hours)<br/>
                                *Touchscreen Control Panel (1)<br/>
                                * Door Contact (2)<br/>
                                * Motion Sensor (1)<br/>
                                * Smoke & Heat Detector (1)<br/>
                                * Doorbell Camera (1)<br/>
                                * Indoor Chime (1)<br/>
                                * Danalock Smart Lock (1)<br/>
                                * Danalock Keypad (1)<br/>
                                * Dana Isreali Cyclinder (1)<br/>
                                * Security Light Switch (1)<br/>
                                * 180deg PTZ Wireless Camera<br/>
                                * HC24 Alarm.com Interactive Gold Subscription<br/>
                                * HC24 Alarm.com Pro Video Subscription<br/>
                                * Installation and programming.<br/>
                        </span>`,
        'pdescription': `Starter Kit for Smart Security System . This systems involves Intrusion & Fire alarm sensors. Includes installation (7.5 Hours)
                                *Touchscreen Control Panel (1)
                                * Door Contact (2)
                                * Motion Sensor (1)
                                * Smoke & Heat Detector (1)
                                * Doorbell Camera (1)
                                * Indoor Chime (1)
                                * Danalock Smart Lock (1)
                                * Danalock Keypad (1)
                                * Dana Isreali Cyclinder (1)
                                * Security Light Switch (1)
                                * 180deg PTZ Wireless Camera
                                * HC24 Alarm.com Interactive Gold Subscription
                                * HC24 Alarm.com Pro Video Subscription
                                * Installation and programming.`,
        'price': "2602.48",
        "items": {
            "all": {
                "00": "1", "12": "2", "10": "1", "11": "1", "05": "1", "06": "1", "01": "1", "02": "1", "03": "1", "04": "1", "24": "1"
            },
            "accessories": {
                "03": "1", "23": "1", "02": 1
            }
        }
    }
];
export const locations = [
    [
        'LIVING ROOMS',
        ['MAIN LIVING ROOM', 'FAMILY LIVING ROOM', 'PRIVATE LOUNGE'],
        [{ "10": "1", "11": "1", "30": "1", "31": "2", "32": "1", "35": "1" }, { "11": "1", "30": "1", "31": "1", "32": "1", "35": "1" }, { "11": "1", "30": "1", "31": "1", "32": "1", "35": "1" }]
    ],

    [
        'BEDROOMS',
        ['MASTER BEDROOM + BATH', 'MADAM BEDROOM + BATH', 'GUEST BEDROOM + BATH', 'BEDROOM 1 + BATH', 'BEDROOM 2 + BATH', 'BEDROOM 3 + BATH', 'BEDROOM 4 + BATH'],
        [{ "11": "1", "30": "1", "31": "1", "32": "2", "33": "1", "34": "1", "35": "2" }, { "11": "1", "30": "1", "31": "1", "32": "2", "33": "1", "34": "1", "35": "2" }, { "11": "1", "30": "1", "31": "1", "32": "2", "33": "1", "34": "2" }, { "11": "1", "30": "1", "31": "1", "32": "2", "33": "1", "34": "2" }, { "11": "1", "30": "1", "31": "1", "32": "2", "33": "1", "34": "2" }, { "11": "1", "30": "1", "31": "1", "32": "2", "33": "1", "34": "2" }, { "11": "1", "30": "1", "31": "1", "32": "2", "33": "1", "34": "2" }]
    ],

    [
        'OTHER ROOMS',
        ['KITCHEN', 'DINING', 'STUDY', 'GYM', 'ROOM 1', 'ROOM 2', 'ROOM 3'],
        [{ "10": "1", "11": "1", "31": "1", "32": "1", "33": "1", "34": "2" }, { "11": "1", "30": "1", "31": "1", "32": "1", "35": "1" }, { "11": "1", "30": "1", "31": "1", "32": "1", "35": "1" }, { "11": "1", "30": "1", "31": "1", "32": "1", "35": "1" }, { "11": "1", "30": "1", "31": "1", "32": "1", "35": "1" }, { "11": "1", "30": "1", "31": "1", "32": "1", "35": "1" }, { "11": "1", "30": "1", "31": "1", "32": "1", "35": "1" }]
    ],

    [
        'LOBBIES',
        ['GF LOBBY', '1F LOBBY', '2F LOBBY', 'GF STAIRS', '1F STAIRS', '2F STAIRS'],
        [{ "11": "1", "33": "1" }, { "11": "1", "33": "1" }, { "11": "1", "33": "1" }, { "11": "1", "33": "1" }, { "11": "1", "33": "1" }, { "11": "1", "33": "1" },]
    ],

    [
        'TRANSIENT ROOMS',
        ['GUEST TOILET', 'KICHEN STORE', 'BOX ROOM', 'ANY CLOSET'],
        [{ "33": "1" }, { "33": "1" }, { "33": "1" }, { "33": "1" },]
    ],

    [
        'ENTRANCES',
        ['FRONT DOOR', 'BACK DOOR', 'ENTRANCE FOYER'],
        [{ "10": "1", "12": "1", "21": "1" }, { "10": "1", "12": "1", "21": "1" }, { "10": "1", "12": "1", "31": "2", "34": "2" },]
    ],

    [
        'TERRACES/OUTDOORS',
        ['TERRACE', 'GARDEN', 'POOL AREA'],
        []
    ],

    [
        'EXTERNAL',
        ['ENTRANCE PORCH', 'HOUSE FRONT', 'HOUSE LEFT', 'HOUSE RIGHT', 'HOUSE BACK', 'MAIN GATE'],
        [{ "31": "1", "34": "1" },]
    ]
];

export const getfigures = {
    dollarRate: 470,
    dollar: 0,
    naira: 0
}

export const checks = [
    [], [], [], [], [], []
];

export const getquantities = [
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0],
    [0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
];

export const getserviceprices = { '0': '0.00', '1': '0.00', '2': '0.00', '3': '0.00', '4': '0.00', '5': '0.00' };

export const selectMenuitems = {
    2: [1, 2, 3, 4],
    4: {
        0: [],
        5: [],
        all: []
    }
}