import React, { useContext, useEffect, useState } from 'react';
import { send } from 'emailjs-com';
import Cookies from 'universal-cookie';

import { Redirect } from 'react-router';


import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Form, Field } from 'react-final-form';
import { TextField, Select } from 'final-form-material-ui';
import {
    Typography,
    Link,
    Grid,
    Button,
    Checkbox,
    Box
} from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';
import userContext from '../contexts/userContext';
import apiUrlContext from '../contexts/apiUrlContext';

import $ from 'jquery';
import renderPop from '../components/popup';


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://hc24.ng/">
                HC24
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const validate = values => {
    const errors = {};

    if (!values.email) {
        errors.email = 'Required';
    }
    if (!values.password) {
        errors.password = 'Required';
    }

    return errors;
};

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        height: 30
    },
}));



export default function SignIn() {
    const classes = useStyles();

    const { user, putUser } = useContext(userContext);
    const { apiUrl, putUrl } = useContext(apiUrlContext);

    const [redirect, setRedirect] = useState(null);



    const onSubmit = async values => {

        const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
        await sleep(600);
        const ov = JSON.stringify(values, 0, 2);
        const cookies = new Cookies();



        console.log('user', values);

        await fetch(
            `${apiUrl}api/login`,
            {
                method: "POST",
                headers: new Headers({
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify({ data: values })
            }
        )
            .then(res => res.json())
            .then(response => {
                console.log(response);
                if (response.status == 1) {
                    renderPop('success', 'Login Successful.', 2000);

                    setTimeout(function () {

                        const apiToken = response.data.api_token;
                        cookies.set('apiToken', apiToken, { path: '/' });
                        cookies.set('user', response.data, { path: '/' });
                        // localStorage.setItem("user", JSON.stringify(response.data));
                        putUser(response.data);
                    }, 3000)
                }
                else {


                    renderPop('danger', 'Login Failed, Try again.');
                    // alert('failed');
                }
            })
            .catch(error => console.log(error));

    };

    useEffect(() => {
        if (user !== null) {
            setRedirect('/');
        }
        return () => {
            console.log('setUser', user)
            console.log("Local Storage", JSON.parse(localStorage.getItem("user")));
        }
    }, [user])

    if (redirect !== null) {
        return <Redirect to={redirect} />
    }

    const loading = true;

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>

                <img src="/images/HC24.png" height='70px' style={{ marginBottom: 40 }} />
                {/* <LockOutlinedIcon /> */}

                <Typography component="h1" variant="h4">
                    Sign in
                </Typography>
                <Form
                    onSubmit={onSubmit}
                    initialValues={{ email: '', password: '', remember: false }}
                    validate={validate}
                    render={({ handleSubmit, reset, submitting, pristine, values }) => (
                        <form className={classes.form} onSubmit={handleSubmit} noValidate>

                            {/* <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        /> */}
                            <Field
                                fullWidth
                                required
                                variant="outlined"
                                margin="normal"
                                id="email"
                                name="email"
                                component={TextField}
                                type="text"
                                label="Email Address"
                                autofocus
                            />
                            <Field
                                fullWidth
                                required
                                variant="outlined"
                                margin="normal"
                                id="password"
                                name="password"
                                component={TextField}
                                type="password"
                                label="Passcode"
                            />
                            {/* <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                        /> */}
                            <FormControlLabel
                                control={<Field component='input' type='checkbox' id="remember" name="remember" color="primary" />}
                                label="Remember me"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={submitting}
                                className={classes.submit}

                            >
                                {
                                    submitting ?

                                        <CircularProgress style={{ height: 20, width: 20 }} color='white' />
                                        :
                                        "Sign In"
                                }

                            </Button>

                            {/* <Grid container>
                            <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot passcode?
                            </Link>
                            </Grid>
                            <Grid item>
                            <Link href="#" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                            </Grid>
                        </Grid> */}
                        </form>
                    )}
                />
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}