import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';

import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';


import BuildIcon from '@material-ui/icons/Build';
import RouterIcon from '@material-ui/icons/Router';
import LockIcon from '@material-ui/icons/Lock';
import HomeIcon from '@material-ui/icons/Home';
import VideocamIcon from '@material-ui/icons/Videocam';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import SpeakerOutlinedIcon from '@material-ui/icons/SpeakerOutlined';

import orange from '@material-ui/core/colors/orange';

import './tabs.css';

import { getaccessories, services, starterKits, smartIntercom } from '../utils/data';
import { updatePrice } from '../utils/Compute';


import checkContext from '../contexts/checkContext';
import figureContext from '../contexts/figureContext';

import quantityContext from '../contexts/quantityContext';
import servicePriceContext from '../contexts/servicePriceContext';
import billContext from '../contexts/billContext';
import breakdownContext from '../contexts/breakdownContext';
import dataContext from '../contexts/dataContext';


import RoomList from './RoomList';
import ImgMediaCard from './Card';
import { blueGrey, grey } from '@material-ui/core/colors';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}

    //   role="tabpanel"
    //   hidden={value !== index}
    //   id={`scrollable-force-tabpanel-${index}`}
    //   aria-labelledby={`scrollable-force-tab-${index}`}
    //   {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,

    // id: `scrollable-force-tab-${index}`,
    // 'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // width: 500,
  },
  sm: {
    fontSize: '0.7rem'
  },
  colorSwitchBase: {
    color: grey[500],
    '&$colorChecked': {
      color: orange[300],
      '& + $colorBar': {
        backgroundColor: orange[300],
      },
    },
  },
  colorChecked: {
    color: orange[700],
    '&$colorChecked': {
      color: orange[600],
      '& + $colorBar': {
        backgroundColor: orange[500],
      },
    },
  },
  colorBar: {
    color: orange[100],
    '&$colorChecked': {
      color: orange[300],
      '& + $colorBar': {
        backgroundColor: orange[300],
      },
    },
  }

}));

export default function FullWidthTabs(props) {
  const setChecked = props.checkState;

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);



  const { checked, handleToggle } = useContext(checkContext);


  const { apiData, Datahandler } = useContext(dataContext);
  const systemsData = apiData.data1;


  let lightingSwitches = checked[3][0] ? checked[3][0] : false;
  let survSwitches = checked[2][0] ? checked[2][0] : false;
  let audioSwitches = checked[4][0] ? checked[4][0] : false;


  useEffect(() => {

    console.log(checked)

  }, [checked])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const components = [BuildIcon, LockIcon, VideocamIcon, EmojiObjectsOutlinedIcon, SpeakerOutlinedIcon, RouterIcon];

  return (
    <div className={classes.root}>
      <AppBar position="static" color="white">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="on"
          aria-label="full width scrollable force tabs example"
          className={classes.sm}
        >
          {
            Object.keys(systemsData).map(index => {
              const systemData = systemsData[index];
              const ThisIcon = components[index];
              return (
                <Tab className={classes.sm} label={systemData.system.name} icon={<ThisIcon />} {...a11yProps(index)} />
              )
            })
          }

        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {
          // systemsData.map((service, index) => {
          Object.keys(systemsData).map(function (keyName, index) {
            const systemData = systemsData[index];
            const service = services[index];

            return (
              <TabPanel value={value} index={index} dir={theme.direction}>

                <React.Fragment>
                  {
                    index === 0 ?
                      <Grid container spacing={4} justify="center" style={{ marginLeft: '0px', marginBottom: '20px', overflow: 'hidden !important', width: '100%' }}>
                        {
                          (systemData.products).map((kit, kindex) => {
                            return (
                              <Grid item xs={12} sm={6} md={3} lg={3} style={{ paddingLeft: '10px', paddingRight: '10px' }} >
                                <ImgMediaCard title={kit.name} image={kit.image} height="1200" selected={true} description={kit.description} index={index + '' + kindex} pid={kit.id} radioSelected={(checked[index][0])} radioChange={true} />
                              </Grid>
                            )
                          })
                        }
                      </Grid>
                      :
                      <Grid container spacing={4} justify="center" style={{ marginLeft: '0px', marginBottom: '20px', overflow: 'hidden !important', width: '100%' }}>
                        {
                          (systemData.products).map((product, pindex) => {

                            return (

                              <Grid item xs={12} sm={6} md={3} lg={3} style={{ paddingLeft: '10px', paddingRight: '10px' }} >
                                <ImgMediaCard title={product.name} image={product.image} description={product.description} index={index + '' + pindex} pid={product.id} />
                              </Grid>

                            )

                          })
                        }
                      </Grid>
                  }
                </React.Fragment>

                <span>
                  {
                    index == 2 ?
                      <div style={{ margin: 20, alignItems: "center", textAlign: "Center" }}>
                        <span>No Cabling &nbsp;&nbsp; </span>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={survSwitches}
                              onChange={handleToggle(!survSwitches, index, 'switch')}
                              inputProps={{ 'aria-label': 'controlled' }}
                              classes={{
                                switchBase: classes.colorSwitchBase,
                                checked: classes.colorChecked,
                                bar: classes.colorBar,
                              }}
                            />
                          }
                          label="&nbsp;Add Cabling"
                        />

                      </div>
                      :
                      index == 3 ?
                        <div style={{ margin: 20, alignItems: "center", textAlign: "Center" }}>
                          <span>Lutron Kits &nbsp;&nbsp; </span>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={lightingSwitches}
                                onChange={handleToggle(!lightingSwitches, index, 'switch')}
                                inputProps={{ 'aria-label': 'controlled' }}
                                classes={{
                                  switchBase: classes.colorSwitchBase,
                                  checked: classes.colorChecked,
                                  bar: classes.colorBar,
                                }}
                              />
                            }
                            label="&nbsp;Shelly Kits"
                          />

                        </div>
                        :

                        index == 4 ?
                          <div style={{ margin: 20, alignItems: "center", textAlign: "Center" }}>
                            <span>No Cabling &nbsp;&nbsp; </span>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={audioSwitches}
                                  onChange={handleToggle(!audioSwitches, index, 'switch')}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                  classes={{
                                    switchBase: classes.colorSwitchBase,
                                    checked: classes.colorChecked,
                                    bar: classes.colorBar,
                                  }}
                                />
                              }
                              label="&nbsp;Add Cabling"
                            />

                          </div>
                          :
                          ""
                  }
                </span>

                <div>
                  <RoomList tab={index} exceptions={index == 1 ? ['3', '4', '6', '8'] : index == 2 ? ['4', '5', '6', '8'] : index == 3 ? ['7', '8'] : index == 4 ? ['3', '4', '5', '7', '8'] : index == 5 ? ['3', '4', '5', '7', '8'] : undefined} />
                </div>
              </TabPanel>
            )
          })
        }

      </SwipeableViews>
    </div>
  );
}
