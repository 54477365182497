import React, { useContext } from 'react';
import { send } from 'emailjs-com';

import { Form, Field } from 'react-final-form';
import { TextField, Select } from 'final-form-material-ui';
import {
  Typography,
  Paper,
  Grid,
  Button,
  MenuItem,
} from '@material-ui/core';
import customerContext from '../contexts/customerContext';
import apiUrlContext from '../contexts/apiUrlContext';
import { useEffect } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import validator from 'validator';





const validate = values => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'Required';
  }
  if (!values.lastName) {
    errors.lastName = 'Required';
  }
  if (!values.email) {
    errors.email = 'Required';
  }
  else {
    if (!validator.isEmail(values.email)) {
      errors.email = 'Not a valid email';
    }
  }
  if (!values.phone) {
    errors.phone = 'Required';
  }

  // if (!values.bedrooms) {
  //   errors.bedrooms = 'Required';
  // }

  // if (!values.city) {
  //   errors.city = 'Required';
  // }
  // if (!values.floors) {
  //   errors.floors = 'Required';
  // }

  // if (!values.projectName) {
  //   errors.projectName = 'Required';
  // }
  // if (!values.siteAddress) {
  //   errors.siteAddress = 'Required';
  // }

  return errors;
};

const states = [
  'Abuja',
  'Abia',
  'Adamawa',
  'Akwa Ibom',
  'Anambra',
  'Bauchi',
  'Bayelsa',
  'Benue',
  'Borno',
  'Cross River',
  'Delta',
  'Ebonyi',
  'Edo',
  'Ekiti',
  'Enugu',
  'Gombe',
  'Imo',
  'Jigawa',
  'Kaduna',
  'Kano',
  'Katsina',
  'Kebbi',
  'Kogi',
  'Kwara',
  'Lagos',
  'Nassarawa',
  'Niger',
  'Ogun',
  'Ondo',
  'Osun',
  'Oyo',
  'Plateau',
  'Rivers',
  'Sokoto',
  'Taraba',
  'Yobe',
  'Zamfara'
];

export default function CustomerForm({ closeModal, link }) {

  const { customer, putCustomer } = useContext(customerContext);
  const { apiUrl, putUrl } = useContext(apiUrlContext);

  const loading = true;

  const onSubmit = async values => {
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    await sleep(600);
    const ov = JSON.stringify(values, 0, 2);
    const toSend = { from_name: `${values.firstName} ${values.lastName}`, message: ov }

    // console.log(values);

    // await send(
    //   "service_g5acpxj",
    //   "userinfo_send",
    //   values,
    //   'user_8rIPDFOqhTIFAtD6NyeCp'
    // ).then((response) => {
    //   console.log('SUCCESS!', response.status, response.text);
    // }).catch((err) => {
    //   console.log('FAILED...', err);
    // });

    await fetch(
      `${apiUrl}api/customer`,
      {
        method: "POST",
        headers: new Headers({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({ data: values })
      }
    )
      .then(res => res.json())
      .then(response => {
        console.log('Customer ID: ', response);
      })
      .catch(error => console.log(error));

    const newCustomer = values;
    putCustomer(newCustomer);

    closeModal();
    document.getElementById(link).click();

  };

  useEffect(() => {
    //  console.log(customer);
  }, [customer]);

  return (
    <div style={{ padding: 16, overflowY: 'auto', fontSize: 10 }}>
      <Form
        onSubmit={onSubmit}
        // initialValues={{ employed: true, stooge: 'larry' }}
        validate={validate}
        render={({ handleSubmit, reset, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Paper style={{ padding: 16, fontSize: '10px !important' }}>
              <Grid container spacing={1}>
                <Typography xs={12} variant='h5' style={{ margin: '20px 0px 20px 0px' }}>Personal Information</Typography>
                <Grid item container xs={12} spacing={3}>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="firstName"
                      component={TextField}
                      type="text"
                      label="First Name"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="lastName"
                      component={TextField}
                      type="text"
                      label="Last Name"
                    />
                  </Grid>
                </Grid>
                <Grid item container xs={12} spacing={3}>
                  <Grid item xs={8}>
                    <Field
                      name="email"
                      fullWidth
                      required
                      component={TextField}
                      type="email"
                      label="Email"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      fullWidth
                      required
                      name="phone"
                      component={TextField}
                      type="number"
                      label="Phone Number"
                    />
                  </Grid>
                </Grid>


                <Typography xs={12} variant='h5' style={{ margin: '20px 0px 20px 0px' }}>Project Information</Typography>
                <Grid item container xs={12} spacing={3}>

                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="projectName"
                      component={TextField}
                      type="text"
                      label="Project Name"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      name="city"
                      component={Select}
                      label="Project Location"
                      formControlProps={{ fullWidth: true }}
                    >
                      {
                        states.map((state, index) => {
                          return <MenuItem value={state} key={index}>{state}</MenuItem>
                        })
                      }

                    </Field>
                  </Grid>

                </Grid>

                <Grid item xs={10}>
                  <Field
                    fullWidth
                    required
                    name="siteAddress"
                    component={TextField}
                    type="text"
                    label="Site Address"
                  />
                </Grid>

                <Typography xs={12} variant='h5' style={{ margin: '20px 0px 20px 0px' }}>House Details</Typography>

                <Grid item container xs={12} spacing={3}>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      name="floors"
                      component={Select}
                      label="No. of Floors"
                      formControlProps={{ fullWidth: true }}
                    >
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      name="bedrooms"
                      component={Select}
                      label="No. of Bedrooms"
                      formControlProps={{ fullWidth: true }}
                    >
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                      <MenuItem value="5">5</MenuItem>
                      <MenuItem value="6">6</MenuItem>
                      <MenuItem value="7">7</MenuItem>

                    </Field>
                  </Grid>

                </Grid>



                <Grid item style={{ marginTop: 30 }}>
                  <Button
                    type="button"
                    variant="contained"
                    onClick={closeModal}
                    disabled={submitting}
                  >
                    Close
                  </Button>
                </Grid>

                <Grid item style={{ marginTop: 30 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                  >
                    Submit
                  </Button>
                </Grid>

                {
                  submitting ?
                    // loading ?
                    <Grid item style={{ marginTop: 30, marginLeft: 100 }}>
                      <CircularProgress color="dark" />
                    </Grid>
                    : ""

                }




              </Grid>
            </Paper>
            {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
          </form>
        )}
      />
    </div>
  );
}