import React, { useContext, useState, useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font
} from "@react-pdf/renderer";

import { getfigures } from "../utils/data";
import customerContext from "../contexts/customerContext";


function formatNumber(number) {
  let formatNum = (number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');  // 12,345.67
  return formatNum;
}

function getVat(number) {
  let vat = number * 0.075;
  return vat;
}

function getRate(number) {
  const rate = getfigures.dollarRate;
  let naira = number * rate;
  return naira;
}

function getDate(separator = '', add = 0) {

  let newDate = new Date()
  if (add > 0) {
    newDate.setDate(newDate.getDate() + add);
  }
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${date}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${year}`;
}

const IMAGE_PATH = "/images/cards/";

Font.register({ family: 'Encode Sans SC', src: "https://fonts.googleapis.com/css2?family=Encode+Sans+SC&display=swap", fontStyle: 'normal', fontWeight: 'normal' });

// const styles = StyleSheet.create({
//   page: {
//     backgroundColor: "#ffffff",
//     padding: 20,
//     paddingBottom: 70,
//     paddingTop: 60,
//     fontSize: '8',
//     color: 'rgba(0, 0, 0, 0.7)',
//     fontFamily: 'Encode Sans SC',
//     lineHeight: '1.5',
//   },
//   pageNumber: {
//     borderTop: '1px solid #f58220',
//     position: 'absolute',
//     fontSize: 8,
//     bottom: 5,
//     left: 0,
//     right: 0,
//     textAlign: 'center',
//     color: 'grey',
//   },
//   headText: {
//     fontSize: 10,
//     color: 'rgba(0, 0, 0, 0.8)',
//     display: 'block',
//     margin: 10,
//     textAlign: 'center'
//   },
//   header: {
//     position: 'absolute',
//     top: 20,
//     left: 20,
//   },
//   section: {
//     padding: 5,
//     paddingTop: 20,
//     flexGrow: 1
//   },
//   thead: {
//     display: 'flex',
//     flexDirection: 'row',
//     borderTop: '1px solid #ddd',
//     padding: 3,
//     fontSize: 6
//   },
//   serviceName: {
//     display: 'block',
//     color: 'white',
//     fontSize: '8',
//     fontWeight: 700,
//     backgroundColor: 'black',
//     padding: 3,
//     paddingLeft: 6
//   },
//   cardContainer: {
//     display: "flex",
//     flexDirection: "row",
//     // height: 60,
//     padding: 5,
//     borderBottom: '1px solid #ddd'
//   },

//   image: {
//     height: 60,
//     flex: 2
//   },

// });

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    padding: 20,
    paddingBottom: 70,
    paddingTop: 60,
    //fontFamily: 'Roboto',
    fontSize: '8',
    color: 'rgba(0, 0, 0, 0.7)',
    fontFamily: 'Encode Sans SC',
    lineHeight: '1.5',
  },
  pageNumber: {
    borderTop: '1px solid #f58220',
    position: 'absolute',
    fontSize: 8,
    bottom: 5,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  headText: {
    fontSize: 10,
    color: 'rgba(0, 0, 0, 0.8)',
    display: 'block',
    margin: 10,
    textAlign: 'center'
  },
  header: {
    position: 'absolute',
    top: 20,
    left: 20,
  },
  section: {
    padding: 5,
    paddingTop: 20,
    flexGrow: 1
  },
  thead: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #ddd',
    padding: 3,
    fontSize: 6
  },
  serviceName: {
    display: 'block',
    color: 'white',
    fontSize: '8',
    fontWeight: 700,
    backgroundColor: 'black',
    padding: 3,
    paddingLeft: 6
  },
  locName: {
    display: 'block',
    color: '#f58220',
    fontSize: '8',
    fontWeight: 700,
    padding: 3,
    paddingLeft: 6,
    borderBottom: '1px solid #ddd'
  },
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    // height : 70,
    padding: 5,
    borderBottom: '1px solid #ddd'
  },

  image: {
    height: 60,
    flex: 1.5
  },

});

export function PdfDocument(props) {

  let grandTotal = 0;
  let finalTotal = 0;

  const dollar = getfigures.dollarRate;

  // const {customer, putCustomer} = useContext(customerContext);

  const customer = props.customer;
  const quoteno = props.quoteno;
  const user = props.user;


  return (
    <Document title="bill_of_materials">
      <Page wrap={false} size='A4' style={styles.page}>
        {/* <View style={{marginBottom: 100, position:"relative"}}> */}
        {/* <Text style={styles.headText}>HOME CONNECT 24</Text> */}

        {/* Header */}
        <View wrap={false} style={styles.header} fixed>
          <Image
            style={{ height: 30 }}
            source={"/images/HC24.png"}
          />
        </View>
        {/* Header End */}

        {/* Front Page */}
        <View style={{ padding: 20, paddingBottom: 10, fontSize: 10 }}>
          <View style={{ textAlign: 'right', marginBottom: 15 }}>
            <Text>{customer['firstName'] !== null ? customer['firstName'] : ''} {customer['lastName'] !== null ? customer['lastName'] : ""}</Text>
            <Text>{customer['siteAddress']}</Text>
            <Text>{customer['city']}</Text>
            <Text>Nigeria</Text>
          </View>
          <Text style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 10 }}>Quotation</Text>

          <Image
            style={{ height: 400, width: '100%' }}
            source={"/images/pdf_cover_page.jpg"}
          />

          <Text style={{ textAlign: 'right', fontSize: 8, marginBottom: 25, marginTop: 4 }}>QU{quoteno}</Text>

          <View style={{ textAlign: 'center' }}>
            <Text style={{ fontSize: 12, color: 'black', marginBottom: 15 }}>{customer['projectName']}</Text>

            <Text style={{ color: 'black' }}>Quotation Date:</Text>
            <Text style={{ marginBottom: 15 }}>{getDate('/')}</Text>

            <Text style={{ color: 'black' }}>Expiry Date:</Text>
            <Text style={{ marginBottom: 10 }}>{getDate('/', 7)}</Text>

            <Text style={{ marginBottom: 0 }}>Prepared By : {user}</Text>
          </View>

        </View>
        {/* Front Page End */}

        <View style={styles.section} break>
          <View wrap={false} style={styles.thead}>
            <Text style={{ flex: 2 }}></Text>
            <Text style={{ flex: 6 }}>ITEM</Text>
            <Text style={{ flex: 1 }}>QUANTITY</Text>
            <Text style={{ flex: 1.5 }}>UNIT PRICE</Text>
            <Text style={{ flex: 2, textAlign: 'right' }}>TOTAL</Text>
          </View>
          {props.data
            ? props.data.map((s, index) => {
              let serviceTotal = 0;
              return (
                <View >
                  <Text wrap={false} key={index} style={styles.serviceName}>{s.name.toUpperCase()}</Text>
                  <View >
                    {(s.rows).map((row) => {
                      let itemTotal = parseFloat(row.quantity) * parseFloat(row.unitprice);
                      serviceTotal += itemTotal;

                      grandTotal += itemTotal;

                      const imageUri = "data:image/png;base64," + row.image;
                      return (
                        <View wrap={false} style={styles.cardContainer}>
                          <Image style={styles.image} src={imageUri} />
                          {/* <Image
                            style={styles.image}
                            source={
                              row.image !== null
                                ? `https://erp.hausba.com/web/image?model=product.template&field=image_512&id=${row.image}&unique=`
                                : "/images/cards/cord.jpg"
                            }
                          /> */}
                          <View style={{ flex: 6, padding: 8 }}>
                            <Text style={{ color: 'rgba(0, 0, 0, 0.9)', marginBottom: '5' }}>{row.title}</Text>
                            <Text>{row.title}</Text>
                            <Text>{row.description}</Text>
                          </View>
                          <Text style={{ flex: 1 }}>{row.quantity}</Text>
                          <Text style={{ flex: 1.5 }}>N{formatNumber(parseFloat(row.unitprice))}</Text>
                          <Text style={{ flex: 2, textAlign: 'right' }}>N{formatNumber(itemTotal)}</Text>

                        </View>
                      )
                    })}
                  </View>
                  <View wrap={false} style={{ display: 'flex', flexDirection: 'row', padding: 4 }}>
                    <Text style={{ flex: 2 }}></Text>
                    <Text style={{ flex: 6 }}></Text>
                    <Text style={{ flex: 1 }}></Text>
                    <Text style={{ flex: 1.5, color: 'rgba(0, 0, 0, 0.9)', }}>Subtotal</Text>
                    <Text style={{ flex: 2, textAlign: 'right' }}>N{formatNumber(serviceTotal)}</Text>
                  </View>

                </View>
              );
            })
            : ""}
          <View style={{ display: 'flex', flexDirection: 'row', padding: 15 }} wrap={false}>

            <Text style={{ flex: 1 }}></Text>
            <View style={{ flex: 1 }}>
              <View style={{ flexDirection: 'row', borderTop: '1px solid black', color: 'black', padding: 3 }}>
                <Text style={{ flex: 1 }}>Project Total</Text>
                <Text style={{ flex: 1, textAlign: 'right' }}>N{formatNumber(grandTotal)}</Text>
              </View>
              <View style={{ flexDirection: 'row', borderTop: '1px solid #ddd', color: 'black', padding: 3 }}>
                <Text style={{ flex: 1 }}>VAT</Text>
                <Text style={{ flex: 1, textAlign: 'right' }}>N{formatNumber(getVat(grandTotal))}</Text>
              </View>
              <View style={{ flexDirection: 'row', borderTop: '1px solid black', color: 'black', padding: 3 }}>
                <Text style={{ flex: 1 }}>TOTAL</Text>
                {(() => {
                  finalTotal = grandTotal + getVat(grandTotal);
                  return <Text style={{ flex: 1, textAlign: 'right' }}>N{formatNumber(finalTotal)}</Text>
                })()}
              </View>

            </View>

          </View>

          {/* <View style={{ display: 'flex', flexDirection: 'row', padding: 15 }}>

            <Text style={{ flex: 2 }}></Text>
            <View style={{ flex: 3 }}>
              <View style={{ flexDirection: 'row', padding: 3 }}>
                <Text style={{ flex: 1 }}>NGN Exchange Rate: {dollar}</Text>
                <Text style={{ flex: 1, textAlign: 'right' }}>NGN Equivalent : {formatNumber(getRate(finalTotal))}</Text>
              </View>

            </View>

          </View>
          <View style={{ fontSize: 6 }}>
            <Text style={{ marginBottom: 15, fontWeight: 700 }}>BANK DETAILS</Text>

            <Text style={{ marginBottom: 7 }}>USD ACCOUNT</Text>
            <Text>A/C Name: HOMECONNECT24 LTD</Text>
            <Text>A/C No: 0004033365</Text>
            <Text style={{ marginBottom: 7 }}>BANK: STANDARD CHARTERED BANK</Text>

            <Text style={{ marginBottom: 7 }}>NAIRA ACCOUNT</Text>
            <Text>A/C Name: HOMECONNECT24 LTD</Text>
            <Text>A/C No: 1017593855</Text>
            <Text>BANK: ZENITH BANK</Text>

          </View> */}
          <View style={{ fontSize: 6 }}>
            <Text style={{ marginBottom: 15, fontWeight: 700 }}>DISCLAIMER:</Text>

            <Text style={{ marginBottom: 7 }}>This is a budgetary estimate for your smart home. Upon approval of this estimate, a final Order Confirmation will be sent to you from orders@hc24.ng to confirm your order once approved.
              Please kindly bear in mind that the final order total might vary slightly with this quotation within a 10% variation.
            </Text>

            <Text style={{ marginBottom: 7 }}>DO NOT make payment on this quote as it will not be processed in our system.</Text>


          </View>






        </View>

        {/* Footer */}
        <View style={styles.pageNumber} fixed >
          <Text style={{ margin: 5 }}>Phone: 07000445566 &nbsp;&nbsp;&nbsp; Email: hello@hc24.ng &nbsp;&nbsp;&nbsp; Web: http://www.hc24.ng</Text>
          <Text style={{ margin: 5 }} >49b Agadez Street, Wuse 2 Abuja</Text>
          <Text style={{ margin: 5 }} render={({ pageNumber, totalPages }) => (
            `Page: ${pageNumber} / ${totalPages}`
          )} />
        </View>
        {/* Footer End */}
        {/* </View> */}
      </Page>
    </Document>
  );
}
