import React, { useState, useEffect, useContext } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import { ShoppingCart } from '@material-ui/icons';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link } from "react-router-dom";
import { Link as MLink } from '@material-ui/core';

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";


import './pricing.css';
import FullWidthTabs from './Tabs';
import ServicePriceDisplay from './ServicePriceDisplay';
import { changeFigures, getfigures, getquantities, getserviceprices, checks, starterKits, services, getaccessories, smartIntercom } from '../utils/data';


import figureContext from '../contexts/figureContext';
import PriceDisplay from './PriceDisplay';
import quantityContext from '../contexts/quantityContext';
import { updatePrice } from '../utils/Compute';
import servicePriceContext from '../contexts/servicePriceContext';
import billContext from '../contexts/billContext';
import checkContext from '../contexts/checkContext';

import CustomerForm from './CustomerForm';
import customerContext from '../contexts/customerContext';
import breakdownContext from '../contexts/breakdownContext';
import apiUrlContext from '../contexts/apiUrlContext';
import userContext from '../contexts/userContext';
import dataContext from '../contexts/dataContext';


import Cookies from 'universal-cookie';



import $ from 'jquery';
import renderPop from './popup';
import SOContext from '../contexts/SOContext';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <MLink color="inherit" href="https://hc24.ng/">
        HC24
      </MLink>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


function scrollFunction(mybutton) {
  if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
    mybutton.style.display = "block";
  } else {
    mybutton.style.display = "none";
  }
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    maxWidth: 1000,
    maxHeight: 600,
    overflow: 'scroll',
    backgroundColor: 'white',
    boxShadow: theme.shadows[5],
    borderRadius: 4,
    border: 'transparent',
    padding: 50,
    '&::focus-visible': {
      outline: '"white"'
    }
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  select: {
    cursor: 'pointer',
    float: 'right',
    display: "inline",
    color: '#495057',
    backgroundColor: 'transparent',
    border: 'none',
    padding: '4px',
    marginTop: '-3px',
    marginLeft: '8px',
    '&::focus': {
      borderColor: 'transparent',
      outline: 0,
      boxShadow: 'none',
    }
  },
  atag: {
    cursor: 'pointer',
    display: "inline",
    color: '#f58220',
    backgroundColor: 'transparent',
    border: 'none',
    padding: '4px',

  }
}));


export default function Pricing() {
  const classes = useStyles();


  const [figures, setFigures] = useState(getfigures);
  const [quantities, setQuanties] = useState([]);
  const [servicePrices, setServicePrices] = useState(getserviceprices);
  const [checked, setChecked] = useState(checks);
  const [newPrices, setNewPrices] = useState([]);

  const { bill, putBill } = useContext(billContext);
  const { SO, putSO } = useContext(SOContext);
  const { apiUrl, putUrl } = useContext(apiUrlContext);

  const { apiData, Datahandler } = useContext(dataContext);



  const [newBill, setNewBill] = useState([])
  const [newBreakdown, setNewBreakdown] = useState([])

  const { breakdown, putBreakdown } = useContext(breakdownContext);

  const { user, putUser } = useContext(userContext);

  const cookies = new Cookies();



  // const increaseFigure = (figure, change) => setFigures({...figures, [figure]:change});
  const increaseFigure = (newFigures) => {
    let setNewFigures = figures;



    for (const figure in newFigures) {
      if (Object.hasOwnProperty.call(newFigures, figure)) {

        const change = newFigures[figure];

        setNewFigures[figure] = change;

      }
    }


    setFigures({ ...figures, setNewFigures })

  };

  const increaseQuantity = (newQuanties) => {
    let setnewQuanties = newQuanties;

    setQuanties(setnewQuanties);
  }

  const updateServicePrice = (newServicePrices) => {
    const oldS = { ...newServicePrices };
    setServicePrices(oldS);
  }

  const handleToggle = (value, tab, from = 'default', portable = 'default') => async () => {
    const newChecked = [...checked];

    if (tab == '0' && from == 'radio') { //if the starter kits were selected

      // newChecked[tab] = [];
      // newChecked[tab].splice(0, 1);

      const ifValue = newChecked[tab][0];

      if (ifValue === value) {
        newChecked[tab][0] = undefined;
      } else {
        newChecked[tab][0] = value;
      }

    }
    else if (tab == 0 && from == 'default') {
      let room = value;

      let item = document.getElementById('doorType' + room).value;

      item = item.trim();


      const ifexists = ("" + room + "" in checked[tab]);
      const newChecked = [...checked];


      if (ifexists == false) {

        newChecked[tab]["" + room + ""] = item;

      } else {
        if (item != '-') {
          newChecked[tab]["" + room + ""] = item;
        }
        else {
          delete newChecked[tab]["" + room + ""];
        }
      }
    }
    else if (tab == 0 && from == 'doorbell') {
      let room = value;

      let quantity = document.getElementById('Doorbell' + room).value;

      quantity = parseInt(quantity);
      // alert(quantity3)

      // const ifexists = ("" + room + "" in checked[tab]);
      // alert(ifexists)
      const newChecked = [...checked];

      // if (ifexists == true) {
      newChecked[tab]["" + room + ""] = quantity;
      // }
      // else {
      //   newChecked[tab]["" + room + ""] = values;
      // }
    }
    else if (tab === 1) { //Smart security
      let room = value;
      let item = '';

      let quantity = 0;
      let quantity2 = 0;
      let quantity3 = 0;

      if (from == 'cquant') {
        quantity = document.getElementById('contactQuantity' + room).value;

        quantity = parseInt(quantity);

        const ifexists = ("" + room + "" in checked[tab]);
        const newChecked = [...checked];


        if (ifexists == true) {
          newChecked[tab]["" + room + ""][1] = quantity;
        }
      }
      else if (from == 'pirquant') {
        quantity2 = document.getElementById('pirQuantity' + room).value;

        quantity2 = parseInt(quantity2);

        const ifexists = ("" + room + "" in checked[tab]);
        const newChecked = [...checked];


        if (ifexists == true) {
          newChecked[tab]["" + room + ""][2] = quantity2;
        }
      }
      else if (from == 'iqremote') {

        quantity3 = document.getElementById('IQRemote' + room).value;

        quantity3 = parseInt(quantity3);
        // alert(quantity3)

        const ifexists = ("" + room + "" in checked[tab]);
        // alert(ifexists)
        const newChecked = [...checked];

        const values = [value, quantity, quantity2, quantity3];


        if (ifexists == true) {
          newChecked[tab]["" + room + ""][3] = quantity3;
        }
        else {
          newChecked[tab]["" + room + ""] = values;
        }
      }
      else {

        quantity = parseInt(quantity);
        quantity2 = parseInt(quantity2);
        quantity3 = parseInt(quantity3);

        const values = [value, quantity, quantity2, quantity3];


        const ifexists = ("" + room + "" in checked[tab]);
        const newChecked = [...checked];


        if (ifexists == false) {
          newChecked[tab]["" + room + ""] = values;
        } else {
          delete newChecked[tab]["" + room + ""];
        }
      }



    }
    else if (tab === 2) {

      if (from == 'switch') {
        newChecked[tab][0] = value;

        if (newChecked[2][0] == true || newChecked[4][0] == true) {
          newChecked[5][0] = true
        }

      }
      else {

        let room = value;

        let item = document.getElementById('surveillanceMenu' + room).value;
        let quantity = 1;

        if (from == 'quant') {
          quantity = document.getElementById('surveillanceQuantity' + room).value;
        }

        quantity = parseInt(quantity);


        item = item.trim();

        const values = [item, quantity];


        const ifexists = ("" + room + "" in checked[tab]);
        const newChecked = [...checked];


        if (ifexists == false) {

          newChecked[tab]["" + room + ""] = values;

        } else {
          if (item != '-') {
            newChecked[tab]["" + room + ""] = values;
          }
          else {
            delete newChecked[tab]["" + room + ""];
          }
        }

        const cameraCheck = [...newChecked];
        let cameras = 0;
        for (const droom in cameraCheck[tab]) {
          if (Object.hasOwnProperty.call(cameraCheck[tab], droom)) {
            const dvalues = cameraCheck[tab][droom];
            cameras += parseInt(dvalues[1]);

          }
        }

        if (cameras > 16) {
          renderPop('warning', "Total amount of cameras selected cannot exceed 16.");
          // alert();
          return;
        }
      }
    }
    else if (tab === 3) {
      if (from == 'switch') {
        newChecked[tab][0] = value;
      }
      else {
        const currentIndex = checked[tab].indexOf(value);

        if (currentIndex === -1) {
          newChecked[tab].push(value);
        } else {
          newChecked[tab].splice(currentIndex, 1);
        }
      }

    }
    else if (tab === 4) {

      if (from == 'switch') {
        newChecked[tab][0] = value;

        if (newChecked[2][0] == true || newChecked[4][0] == true) {
          newChecked[5][0] = true
        }

      }
      else {
        let room = value;
        let item = '';

        if (from != 'pquant') {
          item = document.getElementById('soundMenu' + room).value;
        }
        let quantity = 1;

        if (from == 'quant') {
          quantity = document.getElementById('soundQuantity' + room).value;
        }
        else if (from == 'pquant') {
          quantity = document.getElementById('soundQuantity' + room).value;
          item = portable;
        }

        quantity = parseInt(quantity);


        item = item.trim();

        const values = [item, quantity];


        const ifexists = ("" + room + "" in checked[tab]);
        const newChecked = [...checked];


        if (ifexists == false) {

          newChecked[tab]["" + room + ""] = values;

        } else {
          if (item != '-') {
            newChecked[tab]["" + room + ""] = values;
          }
          else {
            delete newChecked[tab]["" + room + ""];
          }
        }
      }

    }
    else if (tab === 5) {

      if (from == 'switch') {
        newChecked[tab][0] = value;
      }
      else {

        let room = value;
        let item = '';
        let quantity = 1;

        if (from == 'pquant') {
          quantity = document.getElementById('cableQuantity' + room).value;
          item = portable;
        }
        else {
          item = document.getElementById('cablingMenu' + room).value;
        }


        quantity = parseInt(quantity);


        item = item.trim();

        const values = [item, quantity];


        const ifexists = ("" + room + "" in checked[tab]);
        const newChecked = [...checked];


        if (ifexists == false) {

          newChecked[tab]["" + room + ""] = values;

        } else {
          if (item != '-') {
            newChecked[tab]["" + room + ""] = values;
          }
          else {
            delete newChecked[tab]["" + room + ""];
          }
        }
      }

    }

    if (tab == 3) { //smart lighting main living room check

      console.log("Smart Lighting", newChecked[tab], 'Length', newChecked[tab].length);
      if (newChecked[tab] != null && newChecked[tab] != [] && newChecked[tab].length > 1) {
        const currentIndex = checked[tab].indexOf(1); //id of main living room

        console.log('Current Index', currentIndex);

        if (currentIndex === -1) {
          newChecked[tab].push(1);
        } else {
          // newChecked[tab].splice(currentIndex, 1);
        }
      }
    }

    setChecked(newChecked);

    var track = 1;
    for (var t = 1; t < 37; t++) {
      if (newChecked[5][t] != null || newChecked[5][t] != undefined) {
        newChecked[5][0] = true;
      }
      else {
        track++;
      }
    }
    if (track >= 37 && newChecked[2][0] == false && newChecked[4][0] == false) {
      newChecked[5][0] = false;
    }

    await fetch(
      `${apiUrl}api/compute`,
      {
        method: "POST",
        headers: new Headers({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({ data: newChecked })
      }
    )
      .then(res => res.json())
      .then(response => {
        console.log('New Prices', response);
        console.log('SO : ', response.SO);
        setNewPrices(response);
      })
      .catch(error => console.log(error));

  };


  async function setDefault() {

    // const radio = checked[0][0] !== undefined ? checked[0][0] : "225";
    const lightingSwitches = checked[3][0] !== undefined ? checked[3][0] : false;
    const survSwitches = checked[2][0] !== undefined ? checked[2][0] : false;
    const audioSwitches = checked[4][0] !== undefined ? checked[4][0] : false;

    // const value = radio;
    const tab = "0";

    const newChecked = [...checked];

    // newChecked[tab] = [];
    // newChecked[tab][0] = value;
    newChecked[3][0] = lightingSwitches;
    newChecked[2][0] = survSwitches;
    newChecked[4][0] = audioSwitches;

    setChecked(newChecked);

    await fetch(
      `${apiUrl}api/compute`,
      {
        method: "POST",
        headers: new Headers({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({ data: newChecked })
      }
    )
      .then(res => res.json())
      .then(response => {
        console.log(response);
        setNewPrices(response);
      })
      .catch(error => console.log(error));

  }

  async function makeBills(bill) {
    await fetch(
      `${apiUrl}api/bom`,
      {
        method: "POST",
        headers: new Headers({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({ quantities: bill })
      }
    )
      .then(res => res.json())
      .then(response => {
        console.log('Bill of Materials', response);
        setNewBill(response);
      })
      .catch(error => console.log(error));

  }

  async function getBreakdown(bill) {
    await fetch(
      `${apiUrl}api/bomKits`,
      {
        method: "POST",
        headers: new Headers({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({ kits: bill })
      }
    )
      .then(res => res.json())
      .then(response => {
        console.log('Bill of Kits', response);
        setNewBreakdown(response);
      })
      .catch(error => console.log(error));

  }

  useEffect(() => {

    if (newPrices !== []) {

      increaseFigure(newPrices.totalPrice);

      increaseQuantity(newPrices.quantities);

      updateServicePrice(newPrices.systemPrices);

      putBill(newPrices.quantities);

      putBreakdown(newPrices.kits);

      putSO(newPrices.SO);
    }

  }, [newPrices])

  useEffect(() => {
    console.log(breakdown);
    if (breakdown == undefined) {
      return;
    }
    if (Object.keys(breakdown).length > 0) {
      getBreakdown(breakdown);
    }
  }, [breakdown]);

  useEffect(() => {

    setDefault();
    //Get the button
    let thebtn = document.getElementById("floatingDiv");

    // When the customer scrolls down 20px from the top of the document, show the button
    window.onscroll = function () { scrollFunction(thebtn) };
  }, []);

  const [open, setOpen] = React.useState(false);
  const [link, setLink] = React.useState('');

  const { customer, putCustomer } = useContext(customerContext);



  // useEffect(() => {
  //   if (Object.entries(customer).length === 0) {
  //     setOpen(true);
  //   }
  //   else {
  //     setOpen(false);
  //   }
  // }, [customer])


  useEffect(() => {
    console.log("Open: ", open)
  }, [open])

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const userAction = () => {
    let value = document.getElementById('usermenu').value;
    console.log('User Menu', value);

    if (value == 'logout') {
      cookies.remove("user");
      putUser(null);
    }
  }

  const exportButton = e => {
    if (Object.entries(customer).length === 0) {
      e.preventDefault();
      setLink('export')
      setOpen(true);
    }
    else {
      setOpen(false);
    }
  }

  const printButton = e => {
    if (Object.entries(customer).length === 0) {
      e.preventDefault();
      setLink('print')
      setOpen(true);
    }
    else {
      setOpen(false);
    }
  }

  useEffect(() => {

    if (bill == undefined) {
      return;
    }
    if (Object.keys(bill).length > 0) {
      makeBills(bill);
    }

  }, [bill]);

  useEffect(() => {
    console.log('New Bill', newBill);
  }, [newBill])


  const value = { figures, increaseFigure };
  const value2 = { quantities, increaseQuantity };
  const value3 = { servicePrices, updateServicePrice };
  const contextValue = { checked, handleToggle };

  return (
    <React.Fragment>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <CustomerForm closeModal={handleClose} link={link} />
            </div>
          </Fade>
        </Modal>
      </div>
      <figureContext.Provider value={value}>
        <quantityContext.Provider value={value2}>
          <servicePriceContext.Provider value={value3}>
            <checkContext.Provider value={contextValue}>

              <CssBaseline />
              <AppBar position="relative">
                <Toolbar position="relative" className={classes.toolbar}>
                  {/* <CameraIcon className={classes.icon} /> */}
                  {/* <ShoppingCart className={classes.icon} />
                  <Typography variant="h6" color="white" noWrap className={classes.toolbarTitle}>
                    Pricing layout
                  </Typography> */}
                  <div style={{ flexGrow: 1, marginLeft: '70px' }}>
                    <a href="https://hc24.ng" target="_blank"><img src="/images/HC24bg.png" height="30px" /></a>

                  </div>
                  <nav>
                    <a href="https://hc24.ng" target="_blank" className={classes.atag} style={{ textDecoration: 'none' }} >Go to Main Site</a>

                    <select id='usermenu' onChange={() => userAction()} className={classes.select}>
                      <option value="-">{user.name || ''}</option>
                      <option value="logout">Logout</option>
                    </select>

                    {/* <Typography variant="h6" color="secondary" style={{ float: 'right' }} noWrap>{user.name ? user.name : ''}</Typography> */}

                  </nav>

                </Toolbar>
              </AppBar>
              <main>
                {/* Hero unit */}
                <div className={classes.heroContent}>
                  <Container maxWidth="sm">
                    <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                      Pricing layout
                    </Typography>
                    <PriceDisplay color="primary" />
                    <div className={classes.heroButtons}>
                      {/* <figureContext.Consumer> */}
                      <Grid container spacing={2} justify="center">
                        <Grid item>
                          <Link to={{
                            pathname: "/bill",
                            state: {
                              bill: newBill,
                              customer: customer,
                              user: user.name,
                              quoteno: apiData.quotecode,
                              SO: SO
                            },
                          }} style={{ textDecoration: 'none' }} id="export" onClick={exportButton}>
                            <Button variant="contained" color="primary" >
                              Quote Breakdown/Excel
                            </Button>
                          </Link>

                        </Grid>
                        <Grid item>
                          <Link to={{
                            pathname: "/viewBreakdown",
                            state: {
                              breakdown: newBreakdown,
                              customer: customer,
                              bill: newBill,
                              user: user.name,
                              quoteno: apiData.quotecode,
                              SO: SO
                            },
                          }} style={{ textDecoration: 'none' }} id="print">
                            <Button variant="outlined" color="primary" onClick={printButton} >
                              Print Quote
                            </Button>
                          </Link>
                        </Grid>
                      </Grid>
                      {/* </figureContext.Consumer> */}
                    </div>
                  </Container>
                </div>
                <div id="floatingDiv">
                  <PriceDisplay />
                </div>
                <Container className={classes.cardGrid} >
                  {/* End hero unit */}
                  <ServicePriceDisplay />
                  <FullWidthTabs checkState={setChecked} />
                </Container>
              </main>
              {/* Footer */}
              <footer className={classes.footer}>
                <Typography variant="h6" align="center" gutterBottom>
                  HC24 Pricing App
                </Typography>
                <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
                  HC24 pricing app for processing customer orders.
                </Typography>
                <Copyright />
              </footer>
              {/* End footer */}
            </checkContext.Provider>

          </servicePriceContext.Provider>
        </quantityContext.Provider>
      </figureContext.Provider>
    </React.Fragment>
  );
}