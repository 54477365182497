import React, { useEffect, useState } from 'react'
import ReactPDF, { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { PdfDocument } from '../components/PdfDocument';
// import { useLocation } from 'react-router-dom';
// import { Redirect } from 'react-router';

import { Link, Redirect, useLocation } from "react-router-dom";


import Button from '@material-ui/core/Button';

import { saveAs } from 'file-saver';



function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function ViewPdf() {


    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const [redirect, setRedirect] = useState(null);
    const [other, setOther] = useState([]);
    const [ddata, setDdata] = useState([]);
    const [dcustomer, setDCustomer] = useState([]);

    const location = useLocation();

    useEffect(() => {
        if (location.state === undefined) {
            setRedirect('/');
        }
        else {
            const { data, customer, user, quoteno } = location.state;
            if (data == null || data == undefined || data.length == 0) {
                setRedirect('/');
            }
            else {
                console.log(location.state)
                setDdata(data);
                const set = { user: user, quoteno: quoteno };
                console.log('set', set);
                setOther(set);
                setDCustomer(customer);
            }


        }
    }, []);


    if (redirect !== null) {
        return <Redirect to={redirect} />
    }
    // ReactPDF.render(<PdfDocument data={ddata} customer={dcustomer} user={other.user} quoteno={other.quoteno} />, `/example.pdf`);

    return (
        <div>
            <div width='100%' height={windowDimensions.height * 0.2}>
                <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'row', margin: 20 }}>
                    <Link to="/" style={{ textDecoration: 'none', flex: 1 }}>
                        <Button variant="outlined" color="primary">
                            Back
                        </Button>
                    </Link>
                    {/* 
                    <Link to={{
                        pathname: "/viewpdf",
                        state: {

                        },
                    }} style={{ textDecoration: 'none', flex: 1, alignItems: 'center' }}>
                        <Button variant="outlined" color="primary">
                            View PDF
                        </Button>
                    </Link>

                    <Button variant="contained" id="copyBtn" color="primary" style={{ alignItems: 'end' }} >
                        Copy/Download Excel
                    </Button> */}

                    <PDFDownloadLink document={<PdfDocument data={ddata} customer={dcustomer} user={other.user} quoteno={other.quoteno} />} fileName="bill_of_materials.pdf" style={{ textDecoration: "none", alignItems: 'end', marginRight: '10px' }}>
                        <Button variant="contained" color="primary" >
                            Download PDF
                        </Button>
                    </PDFDownloadLink>

                    <PDFDownloadLink
                        document={<PdfDocument data={ddata} customer={dcustomer} user={other.user} quoteno={other.quoteno} />}
                        fileName="bill_of_materials.pdf"
                        style={{
                            alignItems: 'center',
                            textDecoration: "none",
                            paddingTop: "6px",
                            paddingBottom: "6px",
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            borderRadius: 4,
                            color: "#f58220",
                            backgroundColor: "#fff",
                            border: "1px solid #f58220"
                        }}
                    >
                        {({ blob, url, loading, error }) => {
                            const file = new Blob([blob]);
                            return (
                                // loading ? "Loading document..." : "Download Pdf"
                                <span onClick={() => saveAs(file, "bill_of_materials.pdf")}>{loading ? "Loading document..." : "Download PDF (iPad)"}</span>
                            )
                        }
                        }
                    </PDFDownloadLink>
                </div>
            </div>
            <PDFViewer width='100%' height={windowDimensions.height}>
                <PdfDocument data={ddata} customer={dcustomer} user={other.user} quoteno={other.quoteno} />
            </PDFViewer>
        </div>

    )


}
