import React, { useContext, useState, useEffect } from "react";
// import { connect } from "react-redux";

import Cookies from 'universal-cookie';


import { Redirect, Route } from "react-router";
import userContext from "../contexts/userContext";

const AuthRoute = props => {

    const cookies = new Cookies();

    const { user, putUser } = useContext(userContext)

    const [foundUser, setfoundUser] = useState(false);


    let isAuth = false;

    let cookieUser = cookies.get('user');
    console.log("Cookie User 1", cookieUser);
    console.log("Type of : ", typeof (cookieUser));


    if (cookieUser !== null && cookieUser !== undefined && cookieUser != undefined) {
        isAuth = true;
        // console.log("Cookie User 2", JSON.parse(cookieUser));
    }




    if (isAuth === false) return <Redirect to="/login" />;

    return <Route {...props} />;
};

export default AuthRoute;