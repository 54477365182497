import React from 'react';
import './loader.css';

export default function Loader() {
    return (
        <div className='body'>
            <div className="loading">
                <div></div>
                <div></div>
            </div> 
        </div>  
    )
}
