import React, { useState, useEffect, useContext } from 'react';
import figureContext from '../contexts/figureContext';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const PriceDisplay = (props) => {
  const { figures, increaseFigure } = useContext(figureContext);


  return (
    <React.Fragment>
      <Grid container spacing={4} justify="center">
        <Grid item>
          <Typography variant="h6" align="center" color={props.color} >
            TOTAL
          </Typography>
        </Grid>
        {/* <Grid item>
          <Typography variant="h6" align="center" color={props.color}>
            ${figures.dollar}
          </Typography>
        </Grid> */}
        <Grid item>
          <Typography variant="h6" align="center" color={props.color} style={{ fontWeight: 'bold' }}>
            ₦{figures.naira}
          </Typography>
        </Grid>
        {/* <Grid item>
          <Typography variant="h6" align="center" color={props.color}>
            @ ₦{figures.dollarRate}
          </Typography>
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
}

export default PriceDisplay;