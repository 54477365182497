import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import checkContext from '../contexts/checkContext';

import dataContext from '../contexts/dataContext';
import customerContext from '../contexts/customerContext';

import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { grey, orange } from '@material-ui/core/colors';

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";



const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    maxWidth: 1200,
    maxHeight: 800,
    overflow: 'scroll',
    backgroundColor: 'white',
    boxShadow: theme.shadows[5],
    borderRadius: 4,
    border: 'transparent',
    padding: 50,
    '&::focus-visible': {
      outline: '"white"'
    }
  },
  root: {
    width: '100%',

    fontSize: 'small',

    backgroundColor: theme.palette.background.paper,
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
  colorSwitchBase: {
    color: grey[500],
    '&$colorChecked': {
      color: orange[300],
      '& + $colorBar': {
        backgroundColor: orange[300],
      },
    },
  },
  colorChecked: {
    color: orange[700],
    '&$colorChecked': {
      color: orange[600],
      '& + $colorBar': {
        backgroundColor: orange[500],
      },
    },
  },
  colorBar: {
    color: orange[100],
    '&$colorChecked': {
      color: orange[300],
      '& + $colorBar': {
        backgroundColor: orange[300],
      },
    },
  }

}));


export default function RoomList(props) {
  const tab = props.tab;

  const classes = useStyles();

  const { checked, handleToggle } = useContext(checkContext);

  const { customer, putCustomer } = useContext(customerContext);

  // console.log(customer);
  const [open, setOpen] = React.useState(false);
  const [lockType, setLockType] = React.useState('euro');



  const { apiData, Datahandler } = useContext(dataContext);

  const locations = apiData.data2;

  let rackSwitch = checked[5][0] ? checked[5][0] : false;


  useEffect(() => {
    console.log("select change : ", checked)
  }, [checked]);


  const handleClose = () => {
    setOpen(false);
  };
  const sabedrooms = [
    { id: 6, name: "MASTER BEDROOM" },
    { id: 5, name: "MADAM BEDROOM" },
    { id: 4, name: "GUEST BEDROOM" }
  ];

  return (
    <React.Fragment >
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h1>{lockType == 'euro' ? 'Euro Lock' : lockType == 'israeli' ? 'Israeli Lock' : ''}</h1>
            <img style={{ height: 500 }} src={lockType == 'euro' ? '/images/eurolock.jpeg' : lockType == 'israeli' ? '/images/israelilock.jpeg' : ''} />

          </div>
        </Fade>
      </Modal>
      <Grid className={classes.root} container spacing={4} justify="center" style={{ marginLeft: '0px', overflow: 'hidden !important', width: '100%' }}>

        {
          tab == 5 ?

            <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: '0px', borderRadius: '4px', boxShadow: 'rgb(0 0 0 / 20%) 0px 2px 4px', margin: '10px' }} >
              <Typography variant="span" align="center" style={{ width: '100%', padding: '5px', backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', display: 'block' }} >Network Devices</Typography>
              <br />
              <div style={{ padding: '16px' }}>

                {(function () {
                  const room_id = '36';
                  return (
                    <React.Fragment key={room_id} >
                      <div style={{ display: 'inline-block', width: '100%' }}>
                        <Typography variant="span" align="left" >NETWORK RACK KIT</Typography>
                        <div style={{ display: "inline-block", float: "right", }}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={rackSwitch}
                                disabled={true}
                                onChange={handleToggle(!rackSwitch, tab, 'switch')}
                                inputProps={{ 'aria-label': 'controlled' }}
                                classes={{
                                  switchBase: classes.colorSwitchBase,
                                  checked: classes.colorChecked,
                                  bar: classes.colorBar,
                                }}
                              />

                            }
                          />
                        </div>
                        {/* <Checkbox color='primary' style={{ float: 'right', bottom: '10px' }}
                          onChange={handleToggle(room_id, tab)}
                          checked={checked[tab].indexOf(0)}
                          onChange={handleToggle(!(checked[tab].indexOf(0)), tab, 'switch')}
                          inputProps={{ 'aria-labelledby': 'checkbox-list-primary-label-36' }}
                        /> */}
                      </div>

                      <br />
                    </React.Fragment>
                  )
                })()}
                {(function () {
                  const room_id = '37';
                  return (
                    <React.Fragment key={room_id} >
                      <div style={{ display: "inline-block", width: '100%' }}>
                        <Typography variant="span" align="left" >WIRELESS MESH ROUTER</Typography>
                        <div style={{ display: "inline-block", float: "right" }}>

                          <span style={{ fontSize: 11, marginRight: 10 }}>Number of House Floors </span> <input id={"cableQuantity" + room_id} min="0" max="4" onChange={handleToggle(room_id, tab, 'pquant', '139')} type='number' value={(checked[tab][room_id]) !== undefined ? checked[tab][room_id][1] : '0'} style={{ display: "inline", width: 50, float: 'right', marginRight: "25px", color: '#495057', backgroundColor: '#fff', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '4px' }} />

                        </div>
                      </div>
                      <br />
                      <br />
                    </React.Fragment>
                  )
                })()}



              </div>
            </Grid>

            :
            ""
        }

        {locations.map((lgroup, lindex) => {
          const location = lgroup.location_type;
          const rooms = lgroup.rooms;
          const labelId = `checkbox-list-primary-label-${lindex}`;
          if (props.exceptions != undefined) {
            const exceptions = props.exceptions;
            const found = exceptions.find(exception => exception == lindex);

            if (tab == 1) {
              return (

                found === undefined ?

                  <Grid item xs={12} sm={9} md={9} lg={9} style={{ padding: '0px', borderRadius: '4px', boxShadow: 'rgb(0 0 0 / 20%) 0px 2px 4px', margin: '10px' }} >
                    <Typography variant="span" align="center" style={{ width: '100%', padding: '5px', backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', display: 'block' }} >{location.name}</Typography>
                    <br />
                    <div style={{ padding: '16px' }}>
                      {rooms.map((room) => (
                        <React.Fragment key={room.id} >
                          <div style={{ display: 'inline-block', width: '100%' }}>
                            <div style={{ display: "inline", float: "left", width: '35%' }}>
                              <Typography variant="span" align="left" >{room.name}</Typography>
                              <Checkbox color='primary' style={{ bottom: '10px', display: "inline", float: 'right' }}
                                onChange={handleToggle(room.id, tab)}
                                checked={"" + room.id + "" in checked[tab]}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />

                            </div>

                            {(function () {
                              const ifexists = ("" + room.id + "" in checked[tab]);
                              // console.log('exists', ifexists, 'room ', room.name);

                              return (
                                <div style={{ display: "inline", float: "right" }}>
                                  <span>
                                    {

                                      lindex == 7 ?
                                        <span style={{ marginLeft: 70, display: "inline-block" }} >
                                          <Typography style={{ fontSize: 12, display: "inline", color: ifexists ? "#666666" : '#ccc' }} id={'text' + room.id} >Outdoor Magnetic Contact</Typography>
                                          <input min="0" id={"contactQuantity" + room.id} onChange={handleToggle(room.id, tab, 'cquant')} disabled={!ifexists} type='number' value={(checked[tab][room.id]) !== undefined ? checked[tab][room.id][1] : ''} style={{ display: "inline", maxWidth: 40, float: 'right', marginLeft: "20px", color: '#495057', backgroundColor: '#fff', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '4px' }} />
                                        </span>

                                        :
                                        <span style={{ marginLeft: 70, display: "inline-block" }} >
                                          <Typography style={{ fontSize: 12, display: "inline", color: ifexists ? "#666666" : '#ccc' }} id={'text' + room.id} >Door/Window Contact</Typography>
                                          <input min="0" id={"contactQuantity" + room.id} onChange={handleToggle(room.id, tab, 'cquant')} disabled={!ifexists} type='number' value={(checked[tab][room.id]) !== undefined ? checked[tab][room.id][1] : ''} style={{ display: "inline", maxWidth: 40, float: 'right', marginLeft: "20px", color: '#495057', backgroundColor: '#fff', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '4px' }} />
                                        </span>

                                    }
                                  </span>
                                  <span>
                                    {

                                      lindex == 7 ?
                                        <span style={{ marginLeft: 70, display: "inline-block" }}>
                                          <Typography style={{ fontSize: 12, display: "inline", color: ifexists ? "#666666" : '#ccc' }} id={'text' + room.id} >Outdoor PIR</Typography>
                                          <input min="0" id={"pirQuantity" + room.id} onChange={handleToggle(room.id, tab, 'pirquant')} disabled={!ifexists} type='number' value={(checked[tab][room.id]) !== undefined ? checked[tab][room.id][2] : ''} style={{ display: "inline", maxWidth: 40, float: 'right', marginLeft: "20px", color: '#495057', backgroundColor: '#fff', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '4px' }} />
                                        </span>

                                        :

                                        <span style={{ marginLeft: 70, display: "inline-block" }}>
                                          <Typography style={{ fontSize: 12, display: "inline", color: ifexists ? "#666666" : '#ccc' }} id={'text' + room.id} >Indoor PIR</Typography>
                                          <input min="0" id={"pirQuantity" + room.id} onChange={handleToggle(room.id, tab, 'pirquant')} disabled={!ifexists} type='number' value={(checked[tab][room.id]) !== undefined ? checked[tab][room.id][2] : ''} style={{ display: "inline", maxWidth: 40, float: 'right', marginLeft: "20px", color: '#495057', backgroundColor: '#fff', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '4px' }} />
                                        </span>

                                    }
                                  </span>
                                </div>
                              )
                            })()}


                          </div>

                          <br />
                        </React.Fragment>

                      ))}

                    </div>
                  </Grid>
                  :
                  <span></span>
              )
            }
            else if (tab == 2) {
              return (

                found === undefined ?

                  <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: '0px', borderRadius: '4px', boxShadow: 'rgb(0 0 0 / 20%) 0px 2px 4px', margin: '10px' }} >
                    <Typography variant="span" align="center" style={{ width: '100%', padding: '5px', backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', display: 'block' }} >{location.name}</Typography>
                    <br />
                    <div style={{ padding: '16px' }}>
                      {rooms.map((room) => (
                        <React.Fragment key={room.id} >
                          <div style={{ display: "inline-block", width: '100%' }}>
                            <Typography variant="span" align="left" >{room.name}</Typography>
                            <div style={{ display: "inline-block", float: "right" }}>
                              {

                                lindex == 7 ?

                                  <select id={"surveillanceMenu" + room.id} onChange={handleToggle(room.id, tab)} value={(checked[tab][room.id]) !== undefined ? checked[tab][room.id][0] : '-'} style={{ display: "inline", color: '#495057', backgroundColor: '#fff', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '4px', minWidth: 200 }}>
                                    <option value="-"></option>
                                    <option value="6">Outdoor Camera</option>
                                  </select>

                                  :

                                  <select id={"surveillanceMenu" + room.id} onChange={handleToggle(room.id, tab)} value={(checked[tab][room.id]) !== undefined ? checked[tab][room.id][0] : '-'} style={{ display: "inline", color: '#495057', backgroundColor: '#fff', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '4px', minWidth: 200 }}>
                                    <option value="-"></option>
                                    <option value="4">Indoor Camera</option>
                                    <option value="5">Guardian Camera</option>
                                  </select>

                              }
                              <select id={"surveillanceQuantity" + room.id} onChange={handleToggle(room.id, tab, 'quant')} value={(checked[tab][room.id]) !== undefined ? checked[tab][room.id][1] : '0'} style={{ display: "inline", float: 'right', marginLeft: "4px", color: '#495057', backgroundColor: '#fff', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '4px' }}>
                                <option value="0"></option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                              </select>
                            </div>
                          </div>
                          <br />

                          <br />
                        </React.Fragment>

                      ))}

                    </div>
                  </Grid>
                  :
                  <span></span>
              )
            }
            else if (tab == 4) {
              return (

                found === undefined ?

                  <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: '0px', borderRadius: '4px', boxShadow: 'rgb(0 0 0 / 20%) 0px 2px 4px', margin: '10px' }} >
                    <Typography variant="span" align="center" style={{ width: '100%', padding: '5px', backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', display: 'block' }} >{location.name}</Typography>
                    <br />
                    <div style={{ padding: '16px' }}>
                      {rooms.map((room) => (
                        <React.Fragment key={room.id} >
                          <div style={{ display: "inline-block", width: '100%' }}>
                            <Typography variant="span" align="left" >{room.name}</Typography>
                            <div style={{ display: "inline-block", float: "right" }}>
                              {
                                lindex == 0 ?
                                  <select id={"soundMenu" + room.id} onChange={handleToggle(room.id, tab)} value={(checked[tab][room.id]) !== undefined ? checked[tab][room.id][0] : '-'} style={{ display: "inline", color: '#495057', backgroundColor: '#fff', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '4px', minWidth: 300 }}>
                                    <option value="-"></option>
                                    {/* <option value="89">Sonos Home Theatre 5.1 - BEAM</option> */}
                                    <option value="90">Sonos Home Theatre 3.1 - BEAM</option>
                                    <option value="96">Wireless Home Theatre 3.1 - ARC</option>
                                    <option value="97">Wireless Home Theatre 5.1 - ARC</option>
                                    <option value="44">Wireless Upgrade Kit(ARC)</option>
                                    <option value="45">Wireless Upgrade Kit(BEAM)</option>
                                    <option value="14">Wired Upgrade Kit (Large Room - TV Audio)</option>
                                    <option value="15">Wired Upgrade Kit (Small Room - TV Audio)</option>
                                    <option value="21">Wired Home Theatre 5.1 - Large</option>
                                    <option value="146">Wired Upgrade Kit (Small Room)</option>
                                    <option value="147">Wired Upgrade Kit (Large Room)</option>
                                  </select>
                                  :
                                  lindex == 1 ?
                                    <select id={"soundMenu" + room.id} onChange={handleToggle(room.id, tab)} value={(checked[tab][room.id]) !== undefined ? checked[tab][room.id][0] : '-'} style={{ display: "inline", color: '#495057', backgroundColor: '#fff', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '4px', minWidth: 300 }}>
                                      <option value="-"></option>
                                      {/* <option value = "0">6.5" In-ceiling Speaker (Pair)</option>
                                                      <option value = "1">8" In-ceiling Speaker (Pair)</option> */}
                                      <option value="44">Wireless Upgrade Kit(ARC)</option>
                                      <option value="45">Wireless Upgrade Kit(BEAM)</option>
                                      <option value="46">Wireless Upgrade Kit( Large Room )</option>
                                      <option value="48">Wireless Upgrade Kit( Small Room )</option>
                                      {/* <option value="26">Sonos BEAM</option> */}
                                      {/* <option value="27">Sonos FIVE</option>
                                      <option value="29">Sonos ONE</option> */}
                                      <option value="14">Wired Upgrade Kit (Large Room - TV Audio)</option>
                                      <option value="15">Wired Upgrade Kit (Small Room - TV Audio)</option>
                                      <option value="146">Wired Upgrade Kit (Small Room)</option>
                                      <option value="147">Wired Upgrade Kit (Large Room)</option>
                                    </select>
                                    :
                                    lindex == 6 ?
                                      <select id={"soundMenu" + room.id} onChange={handleToggle(room.id, tab)} value={(checked[tab][room.id]) !== undefined ? checked[tab][room.id][0] : '-'} style={{ display: "inline", color: '#495057', backgroundColor: '#fff', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '4px', minWidth: 300 }}>
                                        <option value="-"></option>
                                        {/* <option value = "2">Outdoor On-wall Speaker (Pair)</option> */}
                                        {/* <option value="28">Sonos MOVE</option> */}
                                        <option value="47">Wireless Upgrade Kit( Outdoor )</option>
                                        <option value="148">Wired Upgrade Kit (Large Outdoor)</option>
                                        <option value="149"> Wired Upgrade Kit (Small Outdoor)</option>
                                      </select>

                                      :
                                      <select id={"soundMenu" + room.id} onChange={handleToggle(room.id, tab)} value={(checked[tab][room.id]) !== undefined ? checked[tab][room.id][0] : '-'} style={{ display: "inline", color: '#495057', backgroundColor: '#fff', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '4px', minWidth: 300 }}>
                                        <option value="-"></option>
                                        {/* <option value = "0">6.5" In-ceiling Speaker (Pair)</option>
                                                              <option value = "1">8" In-ceiling Speaker (Pair)</option> */}
                                        <option value="46">Wireless Upgrade Kit( Large Room )</option>
                                        <option value="48">Wireless Upgrade Kit( Small Room )</option>
                                        {/* <option value="27">Sonos FIVE</option>
                                        <option value="29">Sonos ONE</option> */}
                                        <option value="146">Wired Upgrade Kit (Small Room)</option>
                                        <option value="147">Wired Upgrade Kit (Large Room)</option>
                                      </select>

                              }

                              <select id={"soundQuantity" + room.id} onChange={handleToggle(room.id, tab, 'quant')} value={(checked[tab][room.id]) !== undefined ? checked[tab][room.id][1] : '0'} style={{ display: "inline", float: 'right', marginLeft: "4px", color: '#495057', backgroundColor: '#fff', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '4px' }}>
                                <option value="0"></option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                              </select>
                            </div>

                          </div>
                          <br />

                          <br />
                        </React.Fragment>

                      ))}

                    </div>
                  </Grid>
                  :
                  <span></span>
              )
            }

            else if (tab == 5) {
              return (

                found === undefined ?

                  <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: '0px', borderRadius: '4px', boxShadow: 'rgb(0 0 0 / 20%) 0px 2px 4px', margin: '10px' }} >
                    <Typography variant="span" align="center" style={{ width: '100%', padding: '5px', backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', display: 'block' }} >{location.name}</Typography>
                    <br />
                    <div style={{ padding: '16px' }}>
                      {rooms.map((room) => (
                        <React.Fragment key={room.id} >
                          <div style={{ display: "inline-block", width: '100%' }}>
                            <Typography variant="span" align="left" >{room.name}</Typography>
                            <div style={{ display: "inline-block", float: "right" }}>
                              {
                                <select id={"cablingMenu" + room.id} onChange={handleToggle(room.id, tab)} value={(checked[tab][room.id]) !== undefined ? checked[tab][room.id][0] : '-'} style={{ display: "inline", color: '#495057', backgroundColor: '#fff', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '4px', minWidth: 300 }}>
                                  <option value="-"></option>
                                  <option value="182">High Definition Video Cabling</option>
                                  <option value="183">Standard Definition Video Cabling</option>
                                </select>
                              }

                            </div>
                          </div>
                          <br />

                          <br />
                        </React.Fragment>

                      ))}

                    </div>
                  </Grid>
                  :
                  <span></span>
              )
            }

            else {
              return (

                found === undefined ?

                  <Grid item xs={12} sm={6} md={4} lg={3} style={{ padding: '0px', borderRadius: '4px', boxShadow: 'rgb(0 0 0 / 20%) 0px 2px 4px', margin: '10px' }} >
                    <Typography variant="span" align="center" style={{ width: '100%', padding: '5px', backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', display: 'block' }} >{location.name}</Typography>
                    <br />
                    <div style={{ padding: '16px' }}>
                      {rooms.map((room) => (
                        <React.Fragment key={room.id} >
                          <div style={{ display: 'inline-block', width: '100%' }}>
                            <Typography variant="span" align="left" >{room.name}</Typography>
                            <Checkbox color='primary' style={{ float: 'right', bottom: '10px' }}
                              onChange={handleToggle(room.id, tab)}
                              checked={checked[tab].indexOf(room.id) !== -1}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </div>

                          <br />
                        </React.Fragment>

                      ))}

                    </div>
                  </Grid>
                  :
                  <span></span>
              )
            }

          }
          else {
            if (tab != 0) {
              return (
                <Grid item xs={12} sm={6} md={4} lg={3} style={{ padding: '0px', borderRadius: '4px', boxShadow: 'rgb(0 0 0 / 20%) 0px 2px 4px', margin: '10px' }} >
                  <Typography variant="span" align="center" style={{ width: '100%', padding: '5px', backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', display: 'block' }} >{location.name}</Typography>
                  <br />
                  <div style={{ padding: '16px' }}>
                    {rooms.map((room) => (
                      <React.Fragment key={room.id} >
                        <div style={{ display: 'inline-block', width: '100%' }}>
                          <Typography variant="span" align="left" >{room.name}</Typography>
                          <Checkbox color='primary' style={{ float: 'right', bottom: '10px' }}
                            onChange={handleToggle(room.id, tab)}
                            checked={checked[tab].indexOf(room.id) !== -1}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </div>

                        <br />
                      </React.Fragment>

                    ))}

                  </div>
                </Grid>
              );
            }
          }
        })}

        {
          tab == 0 ?
            <React.Fragment>
              <Grid item xs={12} sm={6} md={6} lg={6} style={{ padding: '0px', borderRadius: '4px', boxShadow: 'rgb(0 0 0 / 20%) 0px 2px 4px', margin: '10px' }} >
                <Typography variant="span" align="center" style={{ width: '100%', padding: '5px', backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', display: 'block' }} >Smart Access</Typography>
                <br />
                <div style={{ padding: '16px' }}>
                  <Grid item sm={6} style={{ margin: 20, alignItems: 'flex-end', width: '100%', flexDirection: 'row' }}>
                    <span style={{ marginRight: 20, textAlign: 'center', alignSelf: 'center', flex: 1 }}>Euro door <img src='/images/question.png' alt='' style={{ height: 12, width: 12, cursor: 'pointer' }} onClick={() => { setLockType('euro'); setOpen(true) }} /> </span>
                    <span style={{ textAlign: 'center', alignSelf: 'center', flex: 1 }}>Israeli door <img src='/images/question.png' alt='' style={{ height: 12, width: 12, cursor: 'pointer' }} onClick={() => { setLockType('israeli'); setOpen(true) }} /> </span>
                  </Grid>

                  {(function () {
                    const room_id = '42'; //id for backdoor
                    const labelId = `checkbox-list-primary-label-${room_id}`;
                    const ifexists2 = ("" + room_id + "" in checked[tab]);

                    return (
                      <React.Fragment key={room_id} >

                        <div style={{ display: 'inline-block', width: '100%' }}>
                          <div style={{ display: "inline", float: "left", width: '35%' }}>
                            <Typography variant="span" align="left" >BACK DOOR</Typography>
                            <Checkbox color='primary' style={{ float: 'right', bottom: '10px' }}
                              onChange={handleToggle(room_id, tab)}
                              checked={"" + room_id + "" in checked[tab]}
                              // checked={checked[tab].indexOf(room_id) !== -1}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </div>
                          <div style={{ display: "inline", float: "right" }}>
                            <select id={"doorType" + room_id} disabled={!ifexists2} onChange={handleToggle(room_id, tab)} value={(checked[tab][room_id]) !== undefined ? checked[tab][room_id] : '-'} style={{ display: "inline", color: '#495057', backgroundColor: '#fff', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '4px', minWidth: 200 }}>
                              <option value="-">--Select Your Door Type--</option>
                              <option value="54">Euro Door</option>
                              <option value="131">Israeli Door</option>
                            </select>
                          </div>
                        </div>
                      </React.Fragment>
                    )
                  })()}
                  {(function () {

                    return (
                      <React.Fragment >
                        {
                          sabedrooms.map((room) => (
                            <div style={{ display: 'inline-block', width: '100%' }}>
                              <div style={{ display: "inline", float: "left", width: '35%' }}>

                                <Typography variant="span" align="left" >{room.name}</Typography>
                                <Checkbox color='primary' style={{ float: 'right', bottom: '10px' }}
                                  onChange={handleToggle(room.id, tab)}
                                  checked={"" + room.id + "" in checked[tab]}
                                  inputProps={{ 'aria-labelledby': `checkbox-list-primary-label-${room.id}` }}
                                />
                              </div>
                              <div style={{ display: "inline", float: "right" }}>
                                <select id={"doorType" + room.id} disabled={!("" + room.id + "" in checked[tab])} onChange={handleToggle(room.id, tab)} value={(checked[tab][room.id]) !== undefined ? checked[tab][room.id] : '-'} style={{ display: "inline", color: '#495057', backgroundColor: '#fff', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '4px', minWidth: 200 }}>
                                  <option value="-">--Select Your Door Type--</option>
                                  <option value="54">Euro Door</option>
                                  <option value="131">Israeli Door</option>
                                </select>
                              </div>
                            </div>
                          ))
                        }
                      </React.Fragment>
                    )

                  })()}

                  {(function () {

                    return (
                      <React.Fragment >
                        {
                          (locations[3].rooms).map((room) => (
                            <div style={{ display: 'inline-block', width: '100%' }}>
                              <div style={{ display: "inline", float: "left", width: '35%' }}>

                                <Typography variant="span" align="left" >{room.name}</Typography>
                                <Checkbox color='primary' style={{ float: 'right', bottom: '10px' }}
                                  onChange={handleToggle(room.id, tab)}
                                  checked={"" + room.id + "" in checked[tab]}
                                  inputProps={{ 'aria-labelledby': `checkbox-list-primary-label-${room.id}` }}
                                />
                              </div>
                              <div style={{ display: "inline", float: "right" }}>
                                <select id={"doorType" + room.id} disabled={!("" + room.id + "" in checked[tab])} onChange={handleToggle(room.id, tab)} value={(checked[tab][room.id]) !== undefined ? checked[tab][room.id] : '-'} style={{ display: "inline", color: '#495057', backgroundColor: '#fff', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '4px', minWidth: 200 }}>
                                  <option value="-">--Select Your Door Type--</option>
                                  <option value="54">Euro Door</option>
                                  <option value="131">Israeli Door</option>
                                </select>
                              </div>
                            </div>
                          ))
                        }
                      </React.Fragment>
                    )

                  })()}

                </div>
              </Grid>

              {/* Add Video Doorbell */}

              <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: '0px', borderRadius: '4px', boxShadow: 'rgb(0 0 0 / 20%) 0px 2px 4px', margin: '10px' }} >
                <Typography variant="span" align="center" style={{ width: '100%', padding: '5px', backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', display: 'block' }} >Add Video Doorbells</Typography>
                <br />
                <div style={{ padding: '16px' }}>

                  {(function () {
                    const room_id = '37';
                    return (
                      <React.Fragment key={room_id} >

                        <div style={{ display: "inline-block", width: '100%' }}>
                          <Typography variant="span" align="left" >Video Doorbells</Typography>
                          <div style={{ display: "inline-block", float: "right" }}>

                            <input min="0" max="3" id={"Doorbell" + room_id} onChange={handleToggle(room_id, tab, 'doorbell')} type='number' value={(checked[tab][room_id]) !== undefined ? checked[tab][room_id] : '0'} style={{ display: "inline", float: 'right', marginLeft: "4px", color: '#495057', backgroundColor: '#fff', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '4px', width: '150px' }} />

                          </div>

                        </div>
                        <br />

                        <br />
                      </React.Fragment>
                    )
                  })()}



                </div>
              </Grid>
            </React.Fragment>
            :
            tab == 1 ?


              <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: '0px', borderRadius: '4px', boxShadow: 'rgb(0 0 0 / 20%) 0px 2px 4px', margin: '10px' }} >
                <Typography variant="span" align="center" style={{ width: '100%', padding: '5px', backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', display: 'block' }} >Add IQ Remotes</Typography>
                <br />
                <div style={{ padding: '16px' }}>

                  {(function () {
                    const room_id = '37';
                    return (
                      <React.Fragment key={room_id} >

                        <div style={{ display: "inline-block", width: '100%' }}>
                          <Typography variant="span" align="left" >IQ Remote</Typography>
                          <div style={{ display: "inline-block", float: "right" }}>

                            <input min="0" max="3" id={"IQRemote" + room_id} onChange={handleToggle(room_id, tab, 'iqremote')} type='number' value={(checked[tab][room_id]) !== undefined ? checked[tab][room_id][3] : '0'} style={{ display: "inline", float: 'right', marginLeft: "4px", color: '#495057', backgroundColor: '#fff', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '4px', width: '150px' }} />

                          </div>

                        </div>
                        <br />

                        <br />
                      </React.Fragment>
                    )
                  })()}



                </div>
              </Grid>
              :
              tab == 4 ?

                <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: '0px', borderRadius: '4px', boxShadow: 'rgb(0 0 0 / 20%) 0px 2px 4px', margin: '10px' }} >
                  <Typography variant="span" align="center" style={{ width: '100%', padding: '5px', backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', display: 'block' }} >Portable Speakers</Typography>
                  <br />
                  <div style={{ padding: '16px' }}>

                    {(function () {
                      const room_id = '36';
                      return (
                        <React.Fragment key={room_id} >

                          <div style={{ display: "inline-block", width: '100%' }}>
                            <Typography variant="span" align="left" >SONOS ROAM</Typography>
                            <div style={{ display: "inline-block", float: "right" }}>

                              <input min="0" id={"soundQuantity" + room_id} onChange={handleToggle(room_id, tab, 'pquant', '31')} type='number' value={(checked[tab][room_id]) !== undefined ? checked[tab][room_id][1] : '0'} style={{ display: "inline", float: 'right', marginLeft: "4px", color: '#495057', backgroundColor: '#fff', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '4px' }} />

                            </div>

                          </div>
                          <br />

                          <br />
                        </React.Fragment>
                      )
                    })()}
                    {(function () {
                      const room_id = '37';
                      return (
                        <React.Fragment key={room_id} >
                          <div style={{ display: "inline-block", width: '100%' }}>
                            <Typography variant="span" align="left" >SONOS MOVE</Typography>
                            <div style={{ display: "inline-block", float: "right" }}>

                              <input id={"soundQuantity" + room_id} onChange={handleToggle(room_id, tab, 'pquant', '28')} type='number' value={(checked[tab][room_id]) !== undefined ? checked[tab][room_id][1] : '0'} style={{ display: "inline", float: 'right', marginLeft: "4px", color: '#495057', backgroundColor: '#fff', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '4px' }} />

                            </div>
                          </div>
                          <br />
                          <br />
                        </React.Fragment>
                      )
                    })()}



                  </div>
                </Grid>

                :
                <span></span>

        }

      </Grid>
    </React.Fragment>
  );
}
