import $ from 'jquery';


// renderPop type
export default function renderPop(type, message, time = 5000) {

    hide();

    // data
    var clear;
    var msgDuration = time; // 5 seconds

    var $msgSuccess = 'Great job! Well done :)';
    var $msgDanger = 'Careful with that!';
    var $msgWarning = 'Try that again and see what happens';
    var $msgInfo = 'This is a friendly reminder';

    // cache DOM
    var $msg = $('.msg');

    $msg.on('transitionend', timer(msgDuration, clear));

    switch (type) {
        case 'success':
            $msg.addClass('msg-success active').text(message);
            break;
        case 'danger':
            $msg.addClass('msg-danger active').text(message);
            break;
        case 'warning':
            $msg.addClass('msg-warning active').text(message);
            break;
        case 'info':
            $msg.addClass('msg-info active').text(message);
            break;
    }
}

function timer(msgDuration, clear) {
    clearTimeout(clear);
    clear = setTimeout(function () {
        hide();
    }, msgDuration)
}

function hide() {
    $('.msg').removeClass('msg-success msg-danger msg-warning msg-info active');
}

// bind events
// $btnSuccess.on('click', function () { renderPop('success'); });
// $btnDanger.on('click', function () { renderPop('danger'); });
// $btnWarning.on('click', function () { renderPop('warning'); });
// $btnInfo.on('click', function () { renderPop('info'); });
// $msg.on('transitionend', timer);


