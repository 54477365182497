import React, { useContext, useEffect } from 'react';
import servicePriceContext from "../contexts/servicePriceContext";
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import figureContext from '../contexts/figureContext';

const FormatNumber = (props) => {
    let number = props.number;
    let formatNum = number.replace(/\d(?=(\d{3})+\.)/g, '$&,');  // 12,345.67
    return <span>{formatNum}</span>;
};

const ServicePriceDisplay = () => {
    const { servicePrices, updateServicePrice } = useContext(servicePriceContext);
    const { figures, increaseFigure } = useContext(figureContext);

    return (
        <div >
            <Grid container justify="center" style={{ display: 'flex' }}>
                {
                    // servicePrices.map((sPrice, sindex) => {
                    Object.keys(servicePrices).map(function (index) {
                        const sPrice = servicePrices[index];

                        return (
                            <Grid item className='MuiTab-fullWidth'>
                                <Typography align="center">
                                    ₦{sPrice}
                                    {/* $<FormatNumber number={""+sPrice+""} /> */}
                                </Typography>
                            </Grid>
                        )
                    })
                }

            </Grid>
        </div>
    )
}

export default ServicePriceDisplay;
