import React, { useContext, useState } from 'react';
import { render } from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import quantityContext from '../contexts/quantityContext';
import checkContext from '../contexts/checkContext';


import Radio from '@material-ui/core/Radio';

const useStyles = makeStyles({
  root: {
    maxWidth: 500
  },
  img: {
    objectFit: 'contain'
  }
});

export default function ImgMediaCard(props) {
  const classes = useStyles();

  const { quantities, increaseQuanties } = useContext(quantityContext);

  const { checked, handleToggle } = useContext(checkContext);

  const tab = props.index.charAt(0);
  const pindex = props.index.charAt(1);
  const pid = props.pid;
  let iheight = "140";
  let minHeight = "300px";

  if (props.height) {
    iheight = "250";
    minHeight = "650px";
  }
  let bg = "white"

  const handleChange = props.radioChange;
  const selectedValue = props.radioSelected;


  return (

    <Card className={selectedValue == `${pid}` ? `${classes.root} Mui-selected-Card` : `${classes.root}`} style={{ backgroundColor: bg }} >
      <CardActionArea onClick={handleChange ? handleToggle(pid, tab, 'radio') : ''} style={{ minHeight: minHeight }} >
        <CardMedia
          style={{ marginTop: "0" }}
          component="img"
          alt={props.title}
          height={iheight}
          // image={"/images/cards/" + props.image}
          // image={`https://erp.hausba.com/web/image?model=product.template&field=image_1920&id=${props.image}&unique=`}
          image={`data:image/png;base64,${props.image}`}
          title={props.title}
          classes={
            {
              img: classes.img
            }
          }
        />
        <CardContent>
          <Typography gutterBottom variant="h6" component="h4">
            {props.title}
          </Typography>
          <span style={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "12px", lineHeight: '1.5' }}>
            <div dangerouslySetInnerHTML={{ __html: props.description }} />
          </span>
        </CardContent>
      </CardActionArea>
      <CardActions>
        {
          tab == 0 ?
            <Radio
              checked={selectedValue == `${pid}`}
              onClick={handleChange ? handleToggle(pid, tab, 'radio') : ''}
              // onChange={handleChange ? ()=>selectFunc(pid, tab) : ''}
              name="radio-buttons"
              color='primary'

            // inputProps={{ 'aria-label': 'A' }}
            />
            :
            <Typography size="small" color="primary">
              {quantities[tab] ? (quantities[tab][pid] ? quantities[tab][pid] : 0) : 0}
            </Typography>
        }

        {/* <Button size="small" color="primary">
            Learn More
          </Button> */}
      </CardActions>
    </Card>

  );
}
