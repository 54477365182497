import React, { useState, useEffect, useContext } from "react";
import Pricing from "../components/Pricing";
// import { Loader } from "react-full-page-loader-overlay";
import Loader from "../components/Loader";
import dataContext from "../contexts/dataContext";
import apiUrlContext from "../contexts/apiUrlContext";

const OrderSheet = () => {

  // const [apiData, setSystemsData] = useState([]);
  const { apiData, Datahandler } = useContext(dataContext);
  const { apiUrl, putUrl } = useContext(apiUrlContext);


  console.log(Object.keys(apiData).length);
  const [isLoading, setIsLoading] = useState(Object.keys(apiData).length < 1);

  // const Datahandler = (newData) => {
  //     setSystemsData(newData);
  // }

  useEffect(() => {
    if (isLoading) {
      fetch(
        `${apiUrl}api/fetch`,
        {
          method: "GET",
          // headers: new Headers({
          //   Accept: "application/vnd.github.cloak-preview"
          // })
        }
      )
        .then(res => res.json())
        .then(response => {
          console.log('fetch', response);
          Datahandler(response);
          setIsLoading(false);
        })
        .catch(error => console.log(error));
    }
  }, []);


  useEffect(() => {

    return () => {
      console.log(apiData);
    }
  }, [apiData]);

  // const dataValue = {apiData, Datahandler};

  return (
    <React.Fragment>
      {
        isLoading ?
          // <Loader show={true} design = "1" centerBorder= "#f58220" leftBorder= "crimson" rightBorder ="yellow"/>
          <Loader />
          :
          <Pricing />
      }
    </React.Fragment>

  );
}

export default OrderSheet;