import React, { useEffect, useState, useContext } from 'react'
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { BreakdownDoc } from '../components/BreakdownDoc';
// import { useLocation } from 'react-router-dom';
// import { Redirect } from 'react-router';

import { Link, Redirect, useLocation } from "react-router-dom";
import Button from '@material-ui/core/Button';

import { send } from 'emailjs-com';


import BillsTable from "../components/BillsTable";


import { services, getaccessories, smartIntercom, starterKits, locations } from '../utils/data';
import apiUrlContext from '../contexts/apiUrlContext';
import renderPop from '../components/popup';

import { saveAs } from 'file-saver';



function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

//   function makeBreakdown(bill){

//     let subheads = [];
//     for(let i =0; i<(bill.breakdown).length; i++){
//         const service = services[i].name; //the name of the service
//         const serviceItems = bill.breakdown[i]; //the items of this service
//         const serviceAccessories = bill.accessories[i]; //the accessories of this service


//         let subhead = {name : '', rows : []}; //initial array with the service headinf and its rows
//         // let intercom = {name : 'Smart Intercom', rows : []};
//         subhead.name = service; //set the service heading

//         let aTotal = 0;
//         let atitle = service + " Accessories";
//         let adescription = "Accessories Contain: "; 
//         let aimage;


//         if(i === 0 ){// If this is the starter kit 

//             for (const kit in serviceItems) {
//                 if (Object.hasOwnProperty.call(serviceItems, kit)) {
//                     let lochead = {name : `Starter Kits`, rows : []}; //initial array with the service headinf and its rows

//                     const price = serviceItems[kit];

//                     const kitIndex = kit.charAt(0);

//                     const Kit = starterKits[kitIndex];

//                     let item = {
//                         title : Kit.title,
//                         image : Kit.image,
//                         description : Kit.pdescription,
//                         quantity : "1",
//                         price : Kit.price,
//                     }
//                     lochead.rows.push(item);
//                     subhead.rows.push(lochead);


//                 }
//             }
//         }
//         else { 
//             if(serviceItems.extra){
//                 console.log(i)
//                 console.log(serviceItems.extra);

//                 serviceItems.extra.forEach(extra => {
//                     const detail = services[i].detail[extra];
//                     aTotal += parseFloat(detail.price);
//                     adescription += "\n" + detail.title+ "(1) ";
//                     aimage = detail.image;
//                 });

//                 delete serviceItems.extra;
//             }  
//             for(let j = 0; j < Object.keys(serviceItems).length; j++){ //locations in this service
//                 const location = serviceItems[j];
//                 const locationName = locations[j][0];

//                 let lochead = {name : '', rows : []}; //initial array with the service headinf and its rows
//                 lochead.name = locationName;

//                 for(let k = 0; k < location.length; k++){ //rooms in this locations 
//                     const room = location[k];
//                     let title = service + " " + locations[j][1][k] + " Kit";
//                     let description = locations[j][1][k] + " Kit Contains: ";    

//                     let roomTotal = 0;
//                     let image;
//                     for (const itemI in room) {
//                         if (Object.hasOwnProperty.call(room, itemI)) {
//                             const quantity = room[itemI];
//                             const l = itemI.charAt(0)
//                             const detail = services[i].detail[l];
//                             const price = detail.price;
//                             image = detail.image;

//                             let itemTotal = parseInt(quantity) * parseFloat(price);
//                             roomTotal+= itemTotal;
//                             description += "\n" + detail.title + "(" + quantity + ")";  
//                         }
//                     }
//                     if(roomTotal > 0){
//                         let item = {
//                             title : title,
//                             image : image,
//                             description : description,
//                             quantity : "1",
//                             price : roomTotal.toFixed(2),
//                         }
//                         lochead.rows.push(item);
//                     }

//                 }
//                 if(lochead.rows.length > 0){
//                     subhead.rows.push(lochead);
//                 }
//             }
//         }


//         for (const itemkey in serviceAccessories) {
//             if (Object.hasOwnProperty.call(serviceAccessories, itemkey) && i !== 0) {
//                 let quantity = parseInt(serviceAccessories[itemkey]);

//                 if(quantity > 0){
//                     let detail = getaccessories[i][itemkey];
//                     aimage = detail.image;


//                     let itemTotal = parseInt(quantity) * parseFloat(detail.price);
//                     aTotal+= itemTotal;
//                     adescription += "\n"+ detail.title + "(" + quantity + ") ";    
//                 }         

//             }
//         }

//         if(aTotal !== 0){
//             let lochead = {name : `${service} Accessories`, rows : []}; //initial array with the service headinf and its rows

//             let item = {
//                 title : atitle,
//                 image : aimage,
//                 description : adescription,
//                 quantity : "1",
//                 price : aTotal.toFixed(2),
//             }

//            lochead.rows.push(item); 
//            subhead.rows.push(lochead);

//         }

//         if(subhead.rows.length > 0){
//             subheads.push(subhead);
//         }

//     }

//     let subhead = {name : 'Cables', rows : []}; //initial array with the service headinf and its rows


//     let cables = {name : 'CABLES', rows : []};

//     const serviceCables = bill.accessories[5];

//     for (const itemkey in serviceCables) {
//         if (Object.hasOwnProperty.call(serviceCables, itemkey)) {
//             const quantity = serviceCables[itemkey];

//             let detail = getaccessories['all']['cable'][itemkey];

//           if(quantity != '0') { 
//                 let item = {
//                     title : detail.title,
//                     image : detail.image,
//                     description : detail.pdescription,
//                     quantity : quantity,
//                     price : detail.price,
//                 }
//                 cables.rows.push(item);
//             }
//         }
//     }
//     if(cables.rows.length > 0){
//         subhead.rows.push(cables);
//         subheads.push(subhead);
//     }

//     return subheads;

// }



export default function ViewBreakdown() {

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  const [redirect, setRedirect] = useState(null);
  const [dbreakdown, setDBreakdown] = useState([]);
  const [dcustomer, setDcustomer] = useState([]);
  const [SO, setSO] = useState([]);
  const [other, setOther] = useState([]);
  const [loading, setLoad] = useState(false);
  const { apiUrl, putUrl } = useContext(apiUrlContext);



  const location = useLocation();

  const [Bills, setBills] = useState([]);

  const sendMail = () => {
    const html = document.getElementById("table").innerHTML;
    const values = { ...dcustomer, html: html }

    console.log(values);

    send(
      "service_g5acpxj",
      "user_product",
      values,
      'user_8rIPDFOqhTIFAtD6NyeCp'
    ).then((response) => {
      console.log('SUCCESS!', response.status, response.text);
    }).catch((err) => {
      console.log('FAILED...', err);
    });

  }

  async function getBreakdown(bill) {
    await fetch(
      `${apiUrl}api/bomKits`,
      {
        method: "POST",
        headers: new Headers({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({ kits: bill })
      }
    )
      .then(res => res.json())
      .then(response => {
        console.log('Bill of Kits', response);
        setDBreakdown(response);
      })
      .catch(error => console.log(error));

  }

  useEffect(() => {
    if (location.state === undefined) {
      setRedirect('/');
    }
    else {
      const { breakdown, customer, bill, user, quoteno, SO } = location.state;
      console.log(customer, user, bill)
      setDBreakdown(breakdown);
      // const newBreakdown = makeBreakdown(breakdown);
      // setDBreakdown(newBreakdown); 
      const set = { user: user, quoteno: quoteno };
      console.log('set', set);
      setOther(set);
      setDcustomer(customer);
      setBills(bill);
      setSO(SO);


    }
  }, []);

  useEffect(() => {
    console.log('customer', dcustomer)
    console.log('other', other)
    if (Object.keys(Bills).length > 0) {

      sendMail();
    }
  }, [Bills, dcustomer, other]);


  async function createSO(customer, SO) {
    setLoad(true);

    customer = JSON.stringify(customer)
    await fetch(
      `${apiUrl}api/createSO`,
      {
        method: "POST",
        headers: new Headers({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({ data: { customer: customer, kits: SO } })
      }
    )
      .then(res => res.json())
      .then(response => {
        response.status ? response.status == 1 ? renderPop('success', 'SO created Successfully.') : renderPop('danger', 'An error occurred.') : renderPop('danger', 'An error occurred.')
        setLoad(false)
      })
      .catch(error => console.log(error));

  }

  if (redirect !== null) {
    return <Redirect to={redirect} />
  }

  return (
    // <PDFDownloadLink document={<BreakdownDoc data={dbreakdown} customer= {dcustomer} />} fileName="bill_of_materials.pdf" style={{ textDecoration: "none",  alignItems: 'end' }}>
    //     <Button variant="contained" color="primary" >
    //         Download PDF
    //     </Button> 
    // </PDFDownloadLink>
    <span>
      <div width='100%' height={windowDimensions.height * 0.2}>
        <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'row', margin: 20 }}>
          <Link to="/" style={{ textDecoration: 'none', flex: 1 }}>
            <Button variant="outlined" color="primary">
              Back
            </Button>
          </Link>

          <PDFDownloadLink
            document={<BreakdownDoc data={dbreakdown} customer={dcustomer} user={other.user} quoteno={other.quoteno} />}
            fileName="breakdown.pdf"
            style={{

              textDecoration: 'none',
              marginRight: '10px'
            }}
          >
            <Button variant="contained" color="primary" >
              Download PDF
            </Button>

          </PDFDownloadLink>

          <PDFDownloadLink
            document={<BreakdownDoc data={dbreakdown} customer={dcustomer} user={other.user} quoteno={other.quoteno} />}
            fileName="movielist.pdf"
            target="_blank"
            style={{
              alignItems: 'center',
              textDecoration: "none",
              paddingTop: "6px",
              paddingBottom: "6px",
              paddingLeft: '12px',
              paddingRight: '12px',
              borderRadius: 4,
              color: "#f58220",
              backgroundColor: "#fff",
              border: "1px solid #f58220"
            }}
          >
            {({ blob, url, loading, error }) => {
              const file = new Blob([blob]);
              return (
                // loading ? "Loading document..." : "Download Pdf"
                <span onClick={() => saveAs(file, "breakdown.pdf")}>{loading ? "Loading document..." : "Download PDF (iPad)"}</span>
              )
            }
            }
          </PDFDownloadLink>

          <span style={{ flex: 1, alignItems: 'right' }}>
            <Button disabled={loading} style={{ float: 'right' }} variant="outlined" color="primary" onClick={() => createSO(dcustomer, SO)}>
              {loading ?
                "Loading.."
                :
                "Create An SO"}

            </Button>
          </span>

          {/* <Button variant="contained" id="copyBtn" color="primary" style={{ alignItems: 'end' }} >
            Copy/Download Excel
          </Button> */}

          {/* <PDFDownloadLink document={<PdfDocument data={Bills} customer= {customer} />} fileName="bill_of_materials.pdf" style={{ textDecoration: "none",  alignItems: 'end' }}>
                  <Button variant="contained" color="primary" >
                      Download PDF
                  </Button> 
              </PDFDownloadLink> */}
        </div>
      </div>
      <PDFViewer width='100%' height={windowDimensions.height}>
        <BreakdownDoc data={dbreakdown} customer={dcustomer} user={other.user} quoteno={other.quoteno} />
      </PDFViewer>

      <div id="table" style={{ display: 'none' }}>
        <BillsTable bill={Bills} />

      </div>
    </span>
  )
}
