import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect
} from "react-router-dom";
import billContext from "./contexts/billContext";
import SOContext from "./contexts/SOContext";
import customerContext from "./contexts/customerContext";
import breakdownContext from "./contexts/breakdownContext";
import dataContext from "./contexts/dataContext";
import userContext from "./contexts/userContext";

import BillOfMaterials from "./pages/BillOfMaterials";
import OrderSheet from "./pages/OrderSheet";
import ViewPdf from "./pages/ViewPdf";
import ViewBreakdown from "./pages/ViewBreakdown";
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import apiUrlContext from "./contexts/apiUrlContext";
import SignIn from "./pages/SignIn";

import AuthRoute from "./pages/AuthRoute";

import './components/popup.css';

import Cookies from 'universal-cookie';

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#f58220',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#999999',
      main: '#555555',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    white: {
      main: '#fff'
    },
    textPrimary: {
      color: '#fff'
    }
  },
  typography: {
    // fontSize : 'small',
    button: {
      color: '#fff'

    },
    textPrimary: {
      color: 'white'
    },
    sm: {
      fontSize: 'small'
    }
  }
});

const App = () => {
  const [bill, setBill] = useState([]);

  const [SO, setSO] = useState([]);

  const [customer, setCustomer] = useState({});

  const [breakdown, setBreakdown] = useState({});

  const [apiData, setSystemsData] = useState([]);

  const cookies = new Cookies();

  let getapiUrl = '/';

  if (process.env.NODE_ENV !== 'production') {
    getapiUrl = 'http://127.0.0.1:8000/';
  }

  console.log('get api url', getapiUrl);

  const [apiUrl, setApiUrl] = useState(getapiUrl);

  const [user, setUser] = useState(cookies.get('user') || null);

  const putUser = (newUser) => {
    setUser(newUser)
  }

  const putSO = (newSO) => {
    setSO(newSO)
  }

  const putUrl = (newUrl) => {
    setApiUrl(newUrl)
  }

  const putBill = (newBill) => {
    setBill(newBill)
  }

  const putCustomer = (newCustomer) => {
    setCustomer(newCustomer);
  }

  const putBreakdown = (newBreakdown) => {
    setBreakdown(newBreakdown);
  }

  const Datahandler = (newData) => {
    setSystemsData(newData);
  }



  const apiValue = { apiUrl, putUrl };
  const billValue = { bill, putBill };
  const SOValue = { SO, putSO };
  const customerValue = { customer, putCustomer };
  const breakdownValue = { breakdown, putBreakdown };
  const dataValue = { apiData, Datahandler };
  const userValue = { user, putUser };




  return (
    <ThemeProvider theme={theme}>
      {/* <!-- alert popup message --> */}
      <div class="msg animate slide-in-down"></div>
      {/* <!-- end alert popup message --> */}
      <apiUrlContext.Provider value={apiValue}>
        <billContext.Provider value={billValue}>
          <customerContext.Provider value={customerValue}>
            <breakdownContext.Provider value={breakdownValue}>
              <dataContext.Provider value={dataValue}>
                <userContext.Provider value={userValue}>
                  <SOContext.Provider value={SOValue}>
                    <Router>
                      <Switch>
                        <AuthRoute exact path="/" ><OrderSheet /></AuthRoute>
                        <Route path='/login'><SignIn /></Route>
                        <AuthRoute path="/bill"><BillOfMaterials /></AuthRoute>
                        <Route path="/viewpdf"><ViewPdf /></Route>
                        <Route path="/viewBreakdown"><ViewBreakdown /></Route>
                      </Switch>
                    </Router>
                  </SOContext.Provider>
                </userContext.Provider>
              </dataContext.Provider>
            </breakdownContext.Provider>
          </customerContext.Provider>
        </billContext.Provider>
      </apiUrlContext.Provider>
    </ThemeProvider>
  );
}

export default App;